import React, { useState } from "react";
import {
    DivContainerBoxSC,
    DivFormBoxSC,
    DivFormDataBoxSC,
    DivInputBoxSC,
    H1TitleBoxSC,
    InputSC,
    LabelInputSC,
    LinkNavBarBoxSC,
    LogoNavBarSC,
    TextareaSC,
    TextNavBarSC,
    SpanBudgetSC,
    DivBoxColumnsMoneySC,
    DivBoxTextSC,
    TeaxAreaSC,
    LabelTeaxtAreaSC,
    ButtonsSendCS,
    DivBoxTextBoldSC,
} from "../../styles/styled.brief";
import InputMask from "react-input-mask";
const SoftwareDeveloperBreaf = () => {
    const [phone, setPhone] = useState("");
    const [budget, setBudget] = useState("");
    const [focusedPhone, setFocusedPhone] = useState("");
    const [focusedBudget, setFocusedBudget] = useState("");

    const handlePhoneInput = ({ target }) => {
        setFocusedPhone(target.id);
    };

    const handleBudgetInput = ({ target }) => {
        setFocusedBudget(target.id);
    };
    const [terms, setTerms] = useState(false);
    const [terms2, setTerms2] = useState(false);
    const [terms3, setTerms3] = useState(false);
   
    const Terms = (e) => {
      
        setTerms(e.target.value);
    };
   
    const Terms2 = () => {
      setTerms2(!terms2);
  };
  const Terms3 = () => {
      setTerms3(!terms3);
  };
  


  return (
    <>
    <DivInputBoxSC>
        <DivBoxTextSC>Specify the platforms for which the application is needed</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed1"
              value={"iOS"}
            />

            <SpanBudgetSC className="text1">
              iOS
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed2"
              value={"Android"}
            />

            <SpanBudgetSC className="text">
              Android
            </SpanBudgetSC>
          </label>

        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
 <DivInputBoxSC>
        <DivBoxTextSC>Specify the platforms for which the application is needed</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed1"
              value={"iOS (iPhone)"}
            />

            <SpanBudgetSC className="text1">
              iOS (iPhone)
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed2"
              value={"iOS (iPad)"}
            />

            <SpanBudgetSC className="text1">
              iOS (iPad)
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed3"
              value={"Android (phone)"}
            />

            <SpanBudgetSC className="text1">
              Android (phone)
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed4"
              value={"Android (tablet)"}
            />

            <SpanBudgetSC className="text1">
              Android (tablet)
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>App type</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
     
    <label className="label2">
      <input
        type="radio"
        className="radio"
        name="App type"
        value={"Functional application"}
        checked={terms == 'Functional application' ? true : false}
                            onChange={Terms}
      />
      <span className="fake2"></span>
      <SpanBudgetSC className="text1">
      Functional application
      </SpanBudgetSC>
    </label>
    <label className="label2">
      <input
        type="radio"
        className="radio"
        name="App type"
        value={"Client-server application"}
        checked={terms == 'Client-server application' ? true : false}
                            onChange={Terms}
      />
      <span className="fake2"></span>
      <SpanBudgetSC className="text1">
      Client-server application
      </SpanBudgetSC>
    </label>
    
    <label className="label2">
      <input
      value={"AnotherTypeApp"}
        type="radio"
        className="radio"
        name="App type"
        checked={terms == 'AnotherTypeApp' ? true : false}
                            onChange={Terms}
                            
      />
      <span className="fake2"></span>
      <SpanBudgetSC className="text1">
      Another
      </SpanBudgetSC>
    </label>
    {terms=="AnotherTypeApp"?(<InputSC
                        name="AnotherTypeApp"
                        type={"text"}
                        id="AnotherTypeApp"
                       
                    />):null}
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="App type and web3"
              value={"Web3 & Blockchain"}
            />

            <SpanBudgetSC className="text1">
              Web3 & Blockchain
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Is there a need to create an application management system?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Is it necessary to develop a promotional website?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Is it necessary to develop a promotional website?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Distribution method</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution method"
              value={"Free of charge"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Free of charge
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution method"
              value={"Paid"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Paid
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Monetization method</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Monetization method"
              value={"Internal advertising"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Internal advertising
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Monetization method"
              value={"In-app purchases"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            In-app purchases
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Distribution Account</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution Account"
              value={"Developer Account"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Developer Account
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution Account"
              value={"Own account"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Own account
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>      
      <DivInputBoxSC>
        <DivBoxTextSC>Updating the app content</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Updating the app content"
              value={"There is a server part"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            There is a server part
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Updating the app content"
              value={"You will need to create a server part"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            You will need to create a server part
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Updating the app content"
              value={"Manually"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Manually
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Notifications</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
        <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Notifications1"
              value={"Push notifications"}
            />

            <SpanBudgetSC className="text1">
            Push notifications
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Notifications2"
              value={"SMS"}
            />

            <SpanBudgetSC className="text1">
            SMS
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Will users have a "personal account" in the app?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will users have a personal account in the app?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will users have a personal account in the app?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Will there be authorization via social networks?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will there be authorization via social networks?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will there be authorization via social networks?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What languages are planned in the mobile app?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
        <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app1?"
              value={"English"}
            />

            <SpanBudgetSC className="text1">
            English
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app2?"
              value={"German"}
            />

            <SpanBudgetSC className="text1">
            German
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app3?"
              value={"French"}
            />

            <SpanBudgetSC className="text1">
            French
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app4?"
              value={"Italian"}
            />

            <SpanBudgetSC className="text1">
            Italian
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app5?"
              value={"Russian"}
            />

            <SpanBudgetSC className="text1">
            Russian
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app6?"
              value={"Spanish"}
            />

            <SpanBudgetSC className="text1">
            Spanish
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app7?"
              value={"Other"}
              onChange={Terms2}
              isChecked={terms2}
            />

            <SpanBudgetSC className="text1">
            Other
            </SpanBudgetSC>
          </label>
          {terms2 ? (<InputSC
            name="What languages are planned in the mobile app"
            type={"text"}
            id="What languages are planned in the mobile app"

          />) : null}
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Whether you need a mobile version of the site or adaptive design</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
        <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Whether you need a mobile version of the site or adaptive design1"
              value={"No, not needed"}
            />

            <SpanBudgetSC className="text1">
            No, not needed
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Whether you need a mobile version of the site or adaptive design2"
              value={"Need a mobile version of the site"}
            />

            <SpanBudgetSC className="text1">
            Need a mobile version of the site
            </SpanBudgetSC>
          </label>

          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Whether you need a mobile version of the site or adaptive design3"
              value={"Adaptive design"}
            />

            <SpanBudgetSC className="text1">
            Adaptive design
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
            <DivBoxTextSC >App functions</DivBoxTextSC>
          
            <TeaxAreaSC
                name="App functions"
                type={"text"}
                id="App functions"
                placeholder="Describe what functions the application should perform"
            />
        </DivInputBoxSC>
        <DivInputBoxSC>
            <DivBoxTextSC >App structure</DivBoxTextSC>
            <label className="label2">
            <input
              type="radio"
              className="radio"
              name="App structure"
              value={"The application structure is developed by the contractor"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            The application structure is developed by the contractor
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="App structure"
              value={"The structure of the application is provided by the customer"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            The structure of the application is provided by the customer
            </SpanBudgetSC>
          </label>
          <DivBoxTextSC>Navigating the app</DivBoxTextSC>
          <TeaxAreaSC
                name="Navigating the app"
                type={"text"}
                id="App functions"
                placeholder="Which items should contain the menu"
            />
        </DivInputBoxSC>
        <DivInputBoxSC>
        <DivBoxTextSC>App support</DivBoxTextSC>
        <DivBoxTextSC>Is maintenance necessary after the warranty service of this application?</DivBoxTextSC>

        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="s maintenance necessary after the warranty service of this application?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="s maintenance necessary after the warranty service of this application?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>

        <DivBoxTextSC>How often are improvements and updates of the application planned?</DivBoxTextSC>

        <DivBoxColumnsMoneySC>

<label className="label2">
  <input
    type="radio"
    className="radio"
    name="How often are improvements and updates of the application planned?"
    value={"Improvements as feedback from users arrives"}
  />
  <span className="fake2"></span>
  <SpanBudgetSC className="text1">
  Improvements as feedback from users arrives
  </SpanBudgetSC>
</label>
<label className="label2">
  <input
    type="radio"
    className="radio"
    name="How often are improvements and updates of the application planned?"
    value={"Only necessary updates to support new devices and platform versions"}
  />
  <span className="fake2"></span>
  <SpanBudgetSC className="text1">
  Only necessary updates to support new devices and platform versions
  </SpanBudgetSC>
</label>
<label className="label2">
  <input
    type="radio"
    className="radio"
    name="How often are improvements and updates of the application planned?"
    value={"Not more than once a year"}
  />
  <span className="fake2"></span>
  <SpanBudgetSC className="text1">
  Not more than once a year
  </SpanBudgetSC>
</label>
<label className="label2">
  <input
    type="radio"
    className="radio"
    name="How often are improvements and updates of the application planned?"
    value={"We plan to publish the application and forget about it forever"}
  />
  <span className="fake2"></span>
  <SpanBudgetSC className="text1">
  We plan to publish the application and forget about it forever
  </SpanBudgetSC>
</label>
</DivBoxColumnsMoneySC>

<DivBoxTextSC>Who will own the copyright to the application and in what form
(exclusive or non-exclusive rights)?</DivBoxTextSC>
<InputSC
            name="Who will own the copyright to the application and in what form"
            type={"text"}
            id="OtherTypeSite"

          />
<DivBoxTextSC>Specify any additional data that you consider necessary</DivBoxTextSC>
<TeaxAreaSC
            name="Specify any additional data that you consider necessary"
            type={"text"}
            id="OtherTypeSite"
            placeholder="My answer"

          />
      </DivInputBoxSC>
<DivInputBoxSC><ButtonsSendCS type="submit">Send a brief</ButtonsSendCS></DivInputBoxSC></>
  );
};

export default SoftwareDeveloperBreaf;
