import React from "react";
import {
    ButtonArrowLeftSC,
    ButtonArrowRightSC,
} from "../../styles/styles.Projects";

import {
    DivBoxContentServiceSC,
    DivBoxSliderSC,
    H3Sc,
    DivCustomArrowRight,
    DivCustomArrowLeft,
} from "../../styles/styled-section5";
// import Carousel, {
//     slidesToShowPlugin,
//     arrowsPlugin,
// } from "@brainhubeu/react-carousel";
import Carousel from "react-multi-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ItemCarousel from "./components/itemCarousel";
import photo from "../../images/image.png";
import photo2 from "../../images/image2.png";
import photo3 from "../../images/imag3.png";
import photo4 from "../../images/image4.png";
import photo5 from "../../images/image_4.png";
import photo6 from "../../images/image_5.png";
import photo7 from "../../images/GallenaHill.png";
import photo8 from "../../images/Airplane.png";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Glitchv2 from "../glitchv2";
const MainSection5 = (props) => {
    const { bottomRefProjects } = props;

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1920 },
            items: 3,
            slidesToSlide: 3,
        },
        desktop: {
            breakpoint: { max: 1920, min: 1800 },
            items: 3,
            slidesToSlide: 3,
        },

        tablet: {
            breakpoint: { max: 1800, min: 900 },
            items: 2,
            slidesToSlide: 2,
        },

        mobile: {
            breakpoint: { max: 900, min: 120 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType },
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <DivCustomArrowRight onClick={() => onClick()} />;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType },
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <DivCustomArrowLeft onClick={() => onClick()} />;
    };
    return (
        <DivBoxContentServiceSC ref={bottomRefProjects}>
            <H3Sc>Projects</H3Sc>

            <DivBoxSliderSC>
                <Carousel
                    infinite={true}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    sliderClass="carouselProjects-slider"
                    itemClass="carouselProjects-item"
                    containerClass="carouselProjects-container"
                    responsive={responsive}
                >
                         <ItemCarousel
                        text={"GallenaHill"}
                        Photo={photo7}
                        href={"https://www.galenahill.com/"}
                        borderRadius={26}
                    />
                      <ItemCarousel
                        text={"Empress"}
                        Photo={photo8}
                        href={"https://empress-jets-lilac.vercel.app/"}
                        borderRadius={26}
                    />
                    <ItemCarousel
                        text={"MAT"}
                        Photo={photo3}
                        href={"https://modern-art-theory.vercel.app/"}
                        borderRadius={26}
                    />
                     <ItemCarousel
                        text={"Catena"}
                        Photo={photo2}
                        href={"https://catena-frontend.vercel.app/"}
                        borderRadius={26}
                    />
                    <ItemCarousel
                        text={"3DOS"}
                        Photo={photo}
                        href={"https://ozzy-osbourne-pwi2.vercel.app/"}
                        borderRadius={26}
                    />
                    <ItemCarousel
                        text={"GeoEco"}
                        Photo={photo4}
                        href={"https://eco-crafts-city.vercel.app/"}
                        borderRadius={26}
                    />
                    
                    <ItemCarousel
                        text={"Arene"}
                        Photo={photo5}
                        href={"https://arene.io/"}
                        borderRadius={26}
                    />
                   
               
                </Carousel>
            </DivBoxSliderSC>
        </DivBoxContentServiceSC>
    );
};

export default MainSection5;
