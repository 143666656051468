import styled from "styled-components/macro";
import Logo from "../images/Logo.svg";
import { Link } from "react-router-dom";

export const DivContainerBoxSC = styled.div`
    margin: 0 auto;
    height: 100%;
    background: #fff;
    max-width: 1420px;
    width: 90vw;
    display: grid;
    grid-template-rows: 100px max-content;
    row-gap: 60px;
    display: grid;
    justify-items: center;

`;
export const DivBoxTextSC = styled.div`
  width: 100%;
 
  height: fit-content;
  display: grid;
  align-self: end;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(12px,2vw,24px);
  line-height: 30px;
  font-family: "Orbitron";
    font-style: normal;
  

`;
export const DivBoxTextBoldSC = styled.div`
  width: 100%;
 
  height: fit-content;
  display: grid;
  align-self: end;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(12px,2vw,32px);
  line-height: 40px;
  font-family: "Orbitron";
    font-style: normal;
  

`;
export const ButtonsSendCS= styled.button`
  width: 200px;
  height: 60px;
  text-align: center;
 align-items: center;
cursor: pointer;
  display: grid;
  align-self: end;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(12px,2vw,16px);
  line-height: 30px;
  font-family: "Orbitron";
    font-style: normal;
    background: rgba(94, 35, 157, 0.2);
border: 1px solid #000000;
box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
border-radius: 30px;

`;


export const SpanBudgetSC = styled.div`
  width: 100%;
  height: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(10px,2vw,24px);
  line-height: 30px;
  text-align: start;
  

`; 
export const DivBoxColumnsMoneySC = styled.div`
  display: grid;
  
  gap: 15px;
  height: fit-content;
  width: 100%;
  //border solid red;
  max-width: max-content;
  

`;
export const LinkNavBarBoxSC = styled(Link)`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    text-decoration: none;
    align-items: center;
    user-select: none;
`;

export const LogoNavBarSC = styled.div`
    background-image: url(${Logo});
    background-size: 140%;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    display: grid;
    background-position: center;
    @media (max-width: 768px) {
        background-size: 150%;
        width: 60px;
        height: 60px;
    }
`;

export const TextNavBarSC = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 24px);
    line-height: 30px;
    color: black;
    font-family: "Orbitron";
    text-decoration: none;
`;

export const DivFormBoxSC = styled.div`
    height: max-content;
    display: grid;
    width: 100%;
    max-width: 940px;
    /* border: 1px solid black; */
`;

export const DivFormDataBoxSC = styled.div`
    display: grid;
    max-width: 940px;
    width: 100%;
    grid-auto-rows: minmax(0px, auto);
    height: 100%;
    row-gap: 40px;
    color: black;
`;

export const H1TitleBoxSC = styled.h1`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    padding: 0;
    margin: 0;
`;

export const DivInputBoxSC = styled.div`
    display: grid;
    grid-template-rows: max-content max-content;
    row-gap: 12px;
`;

export const LabelInputSC = styled.label`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;
    
    font-size: clamp(10px,2vw,24px);
`;
export const LabelTeaxtAreaSC = styled.label`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;
    font-size: clamp(10px,2vw,24px);
`;
export const InputSC = styled.input`
    width: 100%;
    /* width: calc(100% -21px); */
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    outline:none;
    padding: 0px 10px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    font-size: clamp(10px,2vw,24px);
`;
export const TeaxAreaSC = styled.textarea`
    width: 100%;
    /* width: calc(100% -21px); */
    height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    outline:none;
    padding: 0px 10px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    font-size: clamp(12px,2vw,18px);
`;
export const TeaxArea2SC = styled.textarea`
    width: 100%;
    /* width: calc(100% -21px); */
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.6);
    outline:none;
    padding: 0px 10px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    font-size: clamp(12px,2vw,18px);
`;

export const TextareaSC = styled.textarea`
    width: 100%;
    max-width: 940px;
    min-width: 100%;
    min-height: 50px;
    height: 50px;
    /* height: 100%; */
    border: 1px solid rgba(0, 0, 0, 0.6);
    outline:none;
    padding: 8px 10px;
    /* padding: 0; */
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    /* padding: 0; */
    /* margin: 0; */
    resize: vertical;
    box-sizing: border-box;
`;
