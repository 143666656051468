import React, { useContext, useState, useRef, useEffect } from "react";

import {
  BlockModal,
  ButtonPrevModal,
  DivWrapModal,
  IoIosArrowBackSC,
} from "../../../styles/styled-modal";
import { DivWrapSC } from "../../../styles/styled-section4";

import { DivContainerSC } from "../../../styles/styled-DEFAULT";
import {
  DivContainerPenalsSC,
  DivBoxTextSC,
  DivBoxBorderSC,
  DivBoxColumnsMoneySC,
  DivBoxColumnIconTextSC,
  DivBoxIconClipSC,
  DivTextAddAttachmentSC,
  ButtonGradientSC,
  SpanBudgetSC,
  DivBoxBorderBigInputSC,
  DivBoxPersonalDataSC,
  DivBoxPriceSC,
  ButtonGradientSmallSC,
  DivTwoColumnsSC,
  DivTextTellUS,
  TellUsTextSC
} from "../../../styles/styled-getInTouch";

const startingValues = {
  firstName: "",
  companyName: "",
  email: "",
  phone: "",
};
const CyberModal = ({
  title,
  subTitle,
  cropImageModal,
  photoUrl,
  setPhotoUrl,
  photoUrls,
  aspect,
  cropType,
  setFileImg,
  isOpen,
  setIsOpen,
}) => {
  const [name, setName] = useState("");
  const fileInput = useRef();

  const getFile = () => {
    fileInput.current.click();
  };

  return (
    <>
      <BlockModal isOpen={isOpen} contentLabel="Modal">
       
          <DivWrapModal>
            <ButtonPrevModal>
              <IoIosArrowBackSC
                isOpen={isOpen}
                onClick={() => setIsOpen(false)}
              />
            </ButtonPrevModal>

            <DivWrapSC>
              <DivContainerSC>
                <form
                  action="https://formsubmit.co/cyberzenlabs@gmail.com"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <DivContainerPenalsSC>
                    <DivBoxPersonalDataSC>
                      <DivBoxBorderSC
                        label="firstName"
                        type="text"
                        name="firstName"
                        required
                        placeholder="Your Name"
                      />

                      <DivBoxBorderSC
                        label="companyName"
                        type="text"
                        name="companyName"
                        placeholder="Company name"
                      />

                      <DivBoxBorderSC
                        label="email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                      />

                      <DivBoxBorderSC
                        label="phone"
                        type="text"
                        name="phone"
                        placeholder="Phone"
                      />
                    </DivBoxPersonalDataSC>
                    <DivBoxPriceSC>
                      <DivBoxTextSC>Budget</DivBoxTextSC>
                      <DivBoxColumnsMoneySC>
                        <label className="label">
                          <input
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"price-10-25k"}
                          />
                          <span className="fake"></span>
                          <SpanBudgetSC className="text1">
                            $ 10K - $ 25K
                          </SpanBudgetSC>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"price-50-100k"}
                          />
                          <span className="fake"></span>
                          <SpanBudgetSC className="text">
                            $ 50K - $ 100K
                          </SpanBudgetSC>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"price-25-50k"}
                          />
                          <span className="fake"></span>
                          <SpanBudgetSC className="text1">
                            $ 25K - $ 50K
                          </SpanBudgetSC>
                        </label>
                        <label className="label">
                          <input
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"price-100k-or-more"}
                          />
                          <span className="fake"></span>
                          <SpanBudgetSC className="text">
                            $ 100K or more
                          </SpanBudgetSC>
                        </label>
                      </DivBoxColumnsMoneySC>
                    </DivBoxPriceSC>
                    <DivBoxBorderBigInputSC
                      type="textarea"
                      wrap="on"
                      // cols={50}
                      name="desccription"
                      placeholder="Tell us about your project or give a link to the description"
                    />
                    <DivBoxColumnIconTextSC>
                      <DivTwoColumnsSC>
                        {" "}
                        <DivBoxIconClipSC onClick={getFile} />
                        <DivTextAddAttachmentSC>
                          Add attachment
                        </DivTextAddAttachmentSC>
                      </DivTwoColumnsSC>

                      <input
                        type="file"
                        name="attachment"
                        hidden="true"
                        ref={fileInput}
                      ></input>
                      <ButtonGradientSC type="submit">send</ButtonGradientSC>
                    </DivBoxColumnIconTextSC>
                    
                    <div style={{display:"grid",justifyItems: "center"}}> <ButtonGradientSmallSC type="submit">
                      send
                    </ButtonGradientSmallSC></div>
                    <DivTextTellUS>Are you ready to work? <TellUsTextSC to="/brief">Tell us</TellUsTextSC> about your project</DivTextTellUS> 
                  </DivContainerPenalsSC>
                </form>
              </DivContainerSC>
            </DivWrapSC>
          </DivWrapModal>

      </BlockModal>
    </>
  );
};

export default CyberModal;
