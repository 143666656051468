import styled, { keyframes } from "styled-components/macro";
import { Link } from "react-router-dom";

export const DivSection6BoxSC = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: var(--max-width);
    display: grid;
    grid-template-rows: auto max-content;
    row-gap: 50px;
    /* justify-items: center;  */
`;
export const TitleBoxSC = styled.div`
    width: 100%;
    height: fit-content;
    display: grid;
    justify-items: center;
`;

export const DivCalendarSC = styled.div`
    width: 100%;
    max-width: 1720px;
    height: 100%;
    /* height: fit-content; */
    position: relative;
    display: grid;
    justify-items: center;
`;
export const H3Sc = styled.span`
    height: 85px;
    font-family: "Ron-Medium";
    font-style: normal;
    display: grid;
    align-items: center;
    font-weight: 700;
    font-size: clamp(26px, 5vw, 80px);
    line-height: 33px;
    /* identical to box height */
    letter-spacing: 0.085em;
    margin: 0;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    width: fit-content;
    /* padding: 5px; */

    @media (min-width: 961px) {
        /* text-shadow: none; */

        line-height: 59px;
    }
    text-shadow: 0px 4px 25px rgba(213, 22, 255, 0.5);
`;
export const DivShadowBoxSC = styled.div`
    display: ${(p) => (p.isSafari ? "grid" : "none")};
    position: absolute;
    top: 0px;
    width: 80%;
    height: 100%;
    //border-radius: 50%;
    background: linear-gradient(
        90.02deg,
        rgba(23, 175, 249, 0.6) -7.59%,
        rgba(213, 22, 255, 0.6) 93.79%
    );
    filter: blur(100px);
    opacity: 0.7;
`;

export const DivCalendarBoxSC = styled.div`
    width: fit-content;
    /* height: max-content; */
    height: fit-content;
    position: relative;
`;

export const DivWriteToUsContainerSC = styled.div`
    width: 99%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    //border: 1px solid red;
`;
export const DivInnerContainerSC = styled.div`
    //border: 1px solid red;
    width: 95vw;
    height: fit-content;
    //min-height: 500px;
    display: grid;
    grid-template-rows: repeat(2, max-content);
    gap: clamp(10px, 4vw, 60px);
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 700px;
`;
export const DivTitleSC = styled.div`
    font-family: "Ron-Medium";
    font-style: normal;
    font-weight: 700;
    font-size: clamp(24px, 5vw, 80px);
    line-height: fit-content;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    display: grid;
    justify-content: center;
    //border: 1px solid blue;
    text-shadow: 0px 4px 25px rgba(213, 22, 255, 0.5);
`;
export const DivRowsSC = styled.div`
    display: grid;
    justify-self: center;
    grid-template-rows: repeat(3, max-content);
    gap: clamp(10px, 2vw, 20px);
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 500;
    font-size: clamp(14px, 3vw, 32px);
    line-height: fit-content;
    color: #ffffff;
    //border: 1px solid green;
    //max-width: 80vw;
    //min-width: 75vw;
    text-shadow: white 0px 0px 10px;
`;
export const DivColumnsSC = styled.div`
    display: grid;

    grid-template-columns: 40% 60%;
    justify-content: center;
    //width:80vw;
    gap: clamp(5px, 1vw, 20px);
    text-align: left;
    @media (max-width: 380px) {
        grid-template-columns: 35% 60%;
        width: 90vw;
    }
`;
export const DivLeftColumnSC = styled.div`
    width: max-content;
    //border: 1px solid red;
`;
export const DivRightColumnSC = styled.div`
    width: max-content;
    //border: 1px solid red;
`;
const rotate = keyframes`
  0%{
	background-size: 0% 4px;
  }
  50%{
	
	background-size: 50% 4px;
  }
  100% {
    background-size: 100% 4px;
  }
`;
export const DivDecLineSC = styled.div`
    // max-width: 497px;
    display: grid;
    animation: ${rotate} 5s linear infinite;
    animation: ${({ isSafari }) => isSafari && "none"};
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    justify-self: center;
    animation-direction: normal;
    background-repeat: no-repeat;
    width: 100%;
    height: 4px;
    transition: 0.1s;

    @media (max-width: 900px) {
        height: 2px;
    }
`;
