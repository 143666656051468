import React from "react";
import {
    BlockModal,
    ButtonPrevModal,
    DivWrapModal,
    IoIosArrowBackSC,
    VideoPosterSC,
} from "../styles/styled-modal";
import Video from "../video/video.mov";

const Showreel = ({ isOpen, setIsOpen }) => {
    return (
        <BlockModal isOpen={isOpen} contentLabel="Modal">
            <DivWrapModal>
                <ButtonPrevModal>
                    <IoIosArrowBackSC
                        isOpen={isOpen}
                        onClick={() => setIsOpen(false)}
                    />
                </ButtonPrevModal>
                <VideoPosterSC src={Video} preload autoPlay={isOpen} loop ></VideoPosterSC>
            </DivWrapModal>
        </BlockModal>
    );
};

export default Showreel;
