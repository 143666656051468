import styled from "styled-components/macro";

export const DivFooterWrapSC = styled.div`
    position: fixed;
    display: grid;
    height: fit-content;

    width: 95vw;
    bottom: 0;
    justify-self: center;
    //align-self: center;
    align-content: center;
    //border: 1px solid red;
    margin-bottom: 20px;
z-index: 100;
    height: 80px;
`;
export const DivFooterMailSC = styled.div`
    cursor: pointer;
    display: grid;
    justify-self: end;
    //border: 1px solid red;
    z-index: 100;
`;
export const DivGitContainerSC = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    max-width: 100px;
    max-height: 100px;
    //border: 1px solid white;
    position: relative;
    z-index: 100;
`;

export const DivMailBoxSC = styled.div`
    position: absolute;
    z-index: 100;
`;

export const DivBoxImailSC = styled.div`
    width: 30px;
    display: grid;
    align-items: center;
    @media (max-width: 768px) {
        width: 20px;
    }
    @media (max-width: 550px) {
        width: 15px;
    }
`;
