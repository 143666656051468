import styled, { keyframes } from "styled-components/macro";
import Budda from "../images/Budda.png";
export const DivSection1BoxSC = styled.div`
    height: max-content;
    width: 90vw;
    margin: 0 auto;
    /* padding-top: 30px; */
    padding-bottom: 50px;
    max-width: 1720px;
    //margin-top: 100px;
    padding-top: 170px;
    @media (max-width: 600px) {
        padding-top: 120px;
    }
`;
export const DivTextSC = styled.div`
    @media (min-width: 680px) {
        display: none;
    }
    max-width: 600px;
    width: 100%;
`;
export const DivBuddaPngSC = styled.div`
    background-position: center;
    width: 100%;
    height: 330px;
    display: grid;
    align-items: center;
    max-width: 352px;
margin-left: 20px;
    background-image: url(${Budda});
    background-repeat: no-repeat;
    background-size: 100%;
    @media (min-width: 680px) {
        background-repeat: no-repeat;
        background-size: cover;
        max-width: 600px;
        display: grid;
        align-items: center;

        position: relative;
        max-height: 550px;
        width: 400px;
        height: 400px;
    }
    @media (min-width: 1280px) {
        height: 100%;
        width: 100%;
    }
`;
export const DivText2SC = styled.div`
    @media (min-width: 680px) {
        display: block;
        text-shadow: white 0px 0px 10px;
        text-align: left;
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 400;
        font-size: clamp(14px, 2vw, 40px);
        line-height: clamp(18px, 2vw, 46px);
        letter-spacing: 0.05em;
    }

    display: none;
`;

export const DivRowBoxSC = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: max-content max-content;
    /* row-gap: 40px; */
    width: 100%;
    @media (min-width: 680px) {
        grid-template-rows: max-content max-content max-content;
        grid-template-columns: 1fr 1fr;
    }
`;
export const AdiskordSC = styled.p`
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: white;
`;

export const DivFirstRowBoxSC = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: max-content max-content;
    row-gap: 20px;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 680px) {
        grid-column-start: 1;
        grid-column-end: 3;

        padding: 20px 0px;
    }
`;

export const DivTextRowBoxSC = styled.div`
    display: grid;

    grid-template-rows: max-content max-content;
    /* row-gap: 5px; */
    width: 100%;
    height: max-content;
    @media (max-width: 680px) {
        justify-content: center;
        justify-items: center;
    }
    @media (min-width: 680px) {
        width: 350px;
        gap: 40px;
        text-align: left;
        grid-column-start: 1;
        grid-column-end: 2;
        justify-self: flex-start;
        margin-left: 16px;
    }
    @media (min-width: 800px) {
        width: 410px;
        gap: 40px;
        margin-left: 18px;
    }
    @media (min-width: 1000px) {
        width: 650px;
        gap: 60px;
        margin-left: 20px;
    }

    @media (min-width: 1400px) {
        width: 850px;
        gap: 80px;
        margin-left: 30px;
    }
    @media (min-width: 2000px) {
        width: 850px;
        gap: 80px;
        margin-left: 0px;
    }
`;

export const TitleHomeBoxSC = styled.div`
    pointer-events: none;
    /* font-weight: 900; */
    /* font-size: 26px; */
    font-size: clamp(1.8rem, 4.5vw, 4rem);
    letter-spacing: 0.08em;
    background: var(--blue-pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-shadow: none; */
    position: relative;
    width: max-content;
    /* font-family: "Orbitron"; */
    font-family: "Ron-Medium";
    /* text-shadow: white 0px 0px 5px; */

    @media (min-width: 680px) {
        display: none;
    }
`;

export const TitleHomeBox2SC = styled.div`
    display: none;
    @media (min-width: 680px) {
        font-size: clamp(26px, 8vw, 160px);
        line-height: clamp(33px, 8vw, 165px);
        width: 100%;
        display: block;
        pointer-events: none;

        /* font-size: 26px; */

        letter-spacing: 0.08em;
        background: var(--blue-pink);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        /* text-shadow: none; */
        position: relative;
        width: max-content;
        /* font-family: "Orbitron"; */
        font-family: "Ron-Medium";
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
    }
`;
export const DivShadowBoxSC = styled.div`
    display: ${(p) => (p.isSafari ? "grid" : "none")};
    position: absolute;
    top: 0px;
    left: -6%;
    width: 110%;
    height: 100%;
    //border-radius: 50%;
    background: linear-gradient(
        90.02deg,
        rgba(23, 175, 249, 0.6) -7.59%,
        rgba(213, 22, 255, 0.6) 93.79%
    );
    filter: blur(100px);
`;

export const SloganBoxSC = styled.p`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px; */
    text-shadow: white 0px 0px 10px;

    margin: 0;
    padding: 0;

    @media (min-width: 680px) {
        width: 100%;

        font-size: clamp(14px, 3vw, 46px);
        line-height: clamp(18px, 3vw, 52px);
        text-align: left;
    }
`;

const rotate = keyframes`
  0%{
	background-size: 0% 2px;

  }
  50%{
	
	background-size: 50% 2px;
    
  }
  100% {
    background-size: 100% 2px;
  }
`;
export const DivNowSC = styled.p`
    background: var(--blue-pink);
    background-position: bottom left; /*OR bottom left*/
    background-size: 100% 2px;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;

    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px; */
    animation: ${rotate} 1s linear;
    width: 100%;
    min-width: min-content;
    max-width: max-content;
    display: inline-block;
    padding-bottom: 2px;
    @media (min-width: 680px) {
        background: var(--blue-pink);
        background-position: bottom left; /*OR bottom left*/
        background-size: 100% 2px;
        background-repeat: no-repeat;
        font-size: clamp(14px, 3vw, 46px);
        line-height: clamp(18px, 3vw, 52px);
        text-align: left;
        animation: ${rotate} 1s linear;
        width: 100%;
        min-width: min-content;
        max-width: max-content;
        display: inline-block;
        padding-bottom: 2px;
    }
`;

export const DivBuddhaBoxSC = styled.div`
    /* width: 360px; */
    display: grid;
    align-items: center;
    height: 200px;
    position: relative;

    @media (min-width: 680px) {
        grid-column-start: 2;
        grid-column-end: 3;
        height: 260px;
        width: 100%;
    }
    @media (min-width: 1200px) {
        height: 320px;
    }
    @media (min-width: 1600px) {
        max-width: 700px;
        height: 500px;
    }
    @media (max-width: 680px) {
        height: 300px;
        width: 100%;
    }
    @media (max-width: 380px) {
        max-width: 300px;
        height: 250px;
        width: 100%;
    }
`;
export const DivBuddhaBox2SC = styled.div`
    /* width: 360px; */
    display: grid;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    justify-items: center;
    @media (min-width: 680px) {
        grid-column-start: 2;
        grid-column-end: 3;
        min-width: 380px;
        height: 100%;
        width: 100%;
        justify-items: center;
    }
    @media (min-width: 1280px) {
        grid-column-start: 2;
        grid-column-end: 3;
        min-width: 600px;
        height: 100%;
        width: 100%;
        justify-items: center;
        min-height: 550px;
    }
`;

export const BuddhaBackHomeSC = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(58, 180, 250, 0.3) 29.3%,
        rgba(58, 180, 250, 0.3) 78.61%
    );
    filter: blur(205px);
    border-radius: 50%;
    opacity: 0.4;
`;

export const DivSecondRowBoxSC = styled.div`
    width: 100%;
    max-width: 1430px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;

    font-size: clamp(1rem, 2.5vw, 2.5rem);
    /* font-size: 14px; */
    /* line-height: 30px; */
    text-align: center;
    letter-spacing: 0.05em;
    @media (min-width: 680px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }
`;

export const DivTempSC = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1000000;
`;

export const DivDisplayGridSC = styled.div`
    display: none;
    @media (max-width: 620px) {
        display: grid;
        width: 100%;
    }
`;

export const DivTextShowreelSC = styled.div`
    display: grid;
    justify-self: center;
    width: max-content;
    height: max-content;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    @media (max-width: 680px) {
        font-size: 12px;
    }
    @media (max-width: 530px) {
        font-size: 12px;
    }
    @media (max-width: 430px) {
        font-size: 12px;
    }
    @media (max-width: 390px) {
        font-size: 10px;
    }
`;

export const DivBoxDisplayGridTextSC = styled.div`
    display: grid;
    /* grid-template-columns: repeat(2, max-content); */
    gap: 30px;
    width: 100%;
    @media (max-width: 950px) {
        grid-template-columns: 100%;
        /* grid-template-rows: repeat(2, max-content); */
        justify-items: start;
        gap: 17px;
    }
    @media (max-width: 680px) {
        display: none;
    }
`;

export const ButtonShowreelSC = styled.button`
    box-sizing: border-box;
    justify-items: center;
    align-items: center;
    border: none;
    width: 100%;
    min-width: 235px;
    height: 70px;
    background: radial-gradient(
                73.28% 231.6% at 26.72% 52.96%,
                #0e1a5a 0%,
                #0e1a5a 5.27%,
                #21004b 100%
            )
            padding-box,
        linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%) border-box;
    border-radius: 18px;
    border: 3px solid transparent;
    cursor: pointer;
    @media (max-width: 1600px) {
        min-width: 211px;
    }
    @media (max-width: 1100px) {
        line-height: 20px;
        height: 55px;
        min-width: 208px;
    }
    @media (max-width: 950px) {
        width: 207px;
    }
    @media (max-width: 680px) {
        height: 40px;
    }
    :hover {
        scale: 0.97;
        transition: 0.5s;
    }
    :active {
        scale: 0.96;
        transition: 0.5s;
    }
`;

export const DivBoxBackSC = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
`;

export const DivBoxDisplayGridText2SC = styled.div`
    display: none;
    @media (max-width: 680px) {
        display: grid;
        padding-top: 20px;
        grid-template-columns: 100%;
        grid-auto-rows: max-content;
        justify-items: start;
        gap: 17px;
    }
    @media (max-width: 620px) {
        padding-top: 0px;
        /* grid-template-rows: repeat(3, max-content); */
        justify-items: center;
    }
`;
