import React from 'react'
import './styles.css'
import {
	DivGlitchSC
} from '../../styles/styles.glitchv1'

const GlitchV1 = (props) => {
	const {
		color,
		fontFamily,
		fontSize,
		literSpacing = null
	} = props

	return (
		<div className='boxGlitch'>

			<DivGlitchSC
			 color={color}
			  className="glitch"
			  fontFamily={fontFamily}
			  fontSize={fontSize}
			  literSpacing={literSpacing}
			  >
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
				<div className="line">{props.children}</div>
			</DivGlitchSC>
		</div>
	)
}

export default GlitchV1