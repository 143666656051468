import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../images/Logo.svg";
import Burger from "../images/BurgerIcon.svg";

export const HeaderSC = styled.div`
    width: 100%;
    height: 100px;
    background: radial-gradient(
        73.28% 231.6% at 26.72% 52.96%,
        #0e1a5a 0%,
        #0e1a5a 5.27%,
        #21004b 100%
    );
    position: fixed;
    top: 0;
    z-index: 10000;
    -webkit-box-shadow: 0px 3px 3px #d516ff;
    -moz-box-shadow: 0px 3px 3px #d516ff;
    box-shadow: 0px 3px 3px #d516ff;
    display: grid;
    justify-content: center;
    justify-items: center;
    @media (max-width: 480px) {
        height: 40px;
    }
    @media (max-width: 768px) {
        height: 80px;
    }
`;
export const DivTextMenuSC = styled.div`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;
    font-size: clamp(11px, 1vw, 20px);
    line-height: 25px;
    cursor: pointer;
    /* identical to box height */
    text-transform: uppercase;
    color: " rgba(255, 255, 255, 0.45)";
    text-shadow: none;
    :hover {
        color: #ffffff;
        text-shadow: 3px 4px 3px #ffffff;
    }
`;
export const DivLinksScrollSC = styled.div`
    display: grid;
    grid-template-columns: max-content max-content max-content max-content max-content max-content;
    /* gap: 40px; */
    gap: 55px;
    align-items: center;

    /* @media (max-width: 2200px) {
        gap: 75px;
    } */
    @media (max-width: 1200px) {
        display: none;
    }
    @media (max-width: 1800px) {
        gap: 55px;
    }
    @media (max-width: 1500px) {
        gap: 35px;
    }
    @media (max-width: 1000px) {
        gap: 30px;
    }
    @media (max-width: 880px) {
        gap: 20px;
    }
    @media (max-width: 769px) {
        color: white;
        display: none;
    }
`;

export const DivMaxWidthSC = styled.div`
    position: relative;
    max-width: 1720px;
    width: 90vw;
    margin: 0 auto;
    // border:1px solid red;
    @media (max-width: 480px) {
        height: 40px;
    }
    @media (max-width: 768px) {
        height: 80px;
    }
`;
export const FooterLinesSC = styled.div`
    position: absolute;
    pointer-events: none;
    top: -40px;
`;

export const DivBoxTextWriteToUsSC = styled.div`
    width: 90%;
    height: 100%;
    display: grid;
    align-items: start;
    padding-top: 100px;
    @media (max-width: 1000px) {
        width: 100%;
    }
    @media (max-width: 480px) {
        justify-content: center;
    }
`;

export const DivWrapFooterSC = styled.div`
    position: relative;
`;

export const DivContainerNavBarHomeSC = styled.div`
    width: 100%;

    height: 100px;
    //border:1px solid green;
    display: grid;
    /* grid-template-columns: 1fr 1fr ; */
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-items: center;
    /* @media (max-width: 2200px) {
        gap: 200px;
    } */
    @media (min-width: 1800px) {
        gap: 150px;
    }
    @media (max-width: 1800px) {
        gap: 150px;
    }
    @media (max-width: 1500px) {
        gap: 100px;
    }
    @media (max-width: 1200px) {
        gap: 50px;
        grid-template-columns: max-content max-content max-content;
        justify-content: 100%;
    }
    @media (max-width: 900px) {
        /* gap: 25px; */
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        width: 100vw;

        height: 80px;
    }
`;
export const DivContainerNavBarSC = styled.div`
    position: absolute;
    width: 100%;
    //border:1px solid green;
    display: grid;
    height: 100px;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    background: initial;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        height: 80px;
    }
`;
export const DivLogoBoxSC = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
`;

export const LogoNavBarSC = styled.div`
    background-image: url(${Logo});
    background-size: 140%;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    display: grid;
    background-position: center;
    @media (max-width: 768px) {
        background-size: 150%;
        width: 60px;
        height: 60px;
    }
`;

export const TextNavBarSC = styled.h1`
    font-style: normal;
    font-weight: 600;
    font-size: clamp(14px, 2vw, 24px);
    line-height: 30px;
    color: #fff;
    font-family: "Orbitron";
`;

export const LinkToHomeSC = styled(Link)`
    text-decoration: none;
    width: max-content;
    height: max-content;
`;

export const LinkToBurgerSC = styled(Link)`
    width: max-content;
    display: grid;
    justify-self: end;
    justify-content: end;
`;

export const IconBurgerSC = styled.div`
    background-image: url(${Burger});
    width: 27px;
    height: 22.41px;
    @media (max-width: 480px) {
        scale: 0.7;
    }
`;

export const BurgerMenuDisplaySC = styled.div`
    display: none;
    @media (max-width: 1200px) {
        display: grid;
    }
`;

export const ButtonHeaderSC = styled.button`
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    border-radius: 18px;
    padding: 10px 24px;
    font-family: "Orbitron";
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
    border: none;
    color: #ffffff;
    border-radius: 18px;
    // background: #b034fd;
    z-index: 1;
    width: max-content;
    height: 40px;
    display: grid;
    align-items: center;

    cursor: pointer;
    @media (max-width: 1600px) {
        padding: 10px 14px;
    }
    @media (max-width: 680px) {
        align-items: center;
        justify-content: center;
        border-radius: 16px;
    }
    @media (max-width: 530px) {
        font-size: 12px;
    }
    @media (max-width: 430px) {
        font-size: 12px;
    }
    color: white;
    cursor: pointer;
    :hover {
        scale: 0.97;
        transition: 0.5s;
    }
    :active {
        scale: 0.96;
        transition: 0.5s;
    }
`;

export const DivBoxDisplaySC = styled.div`
    display: none;
    width: 100%;
    height: max-content;
    @media (max-width: 1200px) {
        display: grid;
    }
    @media (max-width: 620px) {
        display: none;
    }
`;

export const DivTextDisplayNoneSC = styled.div`
    display: none;
    @media (max-width: 680px) {
        width: 100%;
        justify-content: center;
        display: grid;
    }
`;
