import styled from "styled-components";
import { CgChevronRight, CgChevronLeft } from "react-icons/cg";

export const DivBoxAboutSC = styled.div`
    margin-bottom: 20px;
    width: calc(100% - 40px);
    max-width: 1420px;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(3, max-content);
    justify-content: center;
    justify-self: center;
    justify-items: center;
    row-gap: 30px;
    @media (min-width: 768px) {
        width: calc(100% - 100px);
    }
    @media (max-width: 768px) {
        width: calc(100% - 100px);
        margin-bottom: 5px;
    }
`;

export const DivBoxTitleSC = styled.div`
    display: grid;
    font-family: "Ron-Medium";
    font-style: normal;
    font-weight: 700;
    font-size: clamp(26px, 5vw, 80px);
    line-height: 33px;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    background-clip: text;
    text-fill-color: transparent;
    /* text-shadow: 0px 4px 12px rgba(213, 22, 255, 0.83); */
    letter-spacing: 0.05em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-shadow: 0px 4px 12px rgba(213, 22, 255, 0.83); */
    @media (min-width: 768px) {
        /* text-shadow: none; */

        line-height: 80px;
    }
    @media (min-width: 1420px) {
        line-height: 100px;
    }
    text-shadow: 0px 4px 25px rgba(213, 22, 255, 0.5);
`;

export const DivBoxteamTextSC = styled.div`
    display: none;
    @media (min-width: 768px) {
        display: grid;
        justify-self: start;
        font-family: "Orbitron";
        font-style: normal;
        font-weight: 400;
        font-size: clamp(26px, 2vw, 45px);
        line-height: 33px;
        background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 12px rgba(213, 22, 255, 0.83);
        letter-spacing: 0.05em;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media (min-width: 1420px) {
        line-height: 56px;
    }
`;

export const DivBoxColumnsPagesSC = styled.div`
    height: 100%;
    width: calc(100vw - 40px);
    min-width: 320px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: 47px;
    border-bottom: 2px solid linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    margin-bottom: 5px;
    justify-content: center;
    @media (min-width: 480px) {
        column-gap: 86px;
    }
    @media (min-width: 768px) {
        width: calc(100vw - 100px);
        justify-content: start;
    }
`;

export const DivPageSC = styled.div`
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 400;
    font-size: clamp(12px, 2vw, 30px);
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-shadow: ${(p) => (p.isActive ? "6px 5px 5px #a557e5" : "#FFF")};
    &:hover {
        text-shadow: 4px 4px 4px #8504ee;
    }
    @media (min-width: 480px) {
        line-height: 30px;
        &:hover {
            text-shadow: 4px 4px 8px #a557e5;
        }
    }
    @media (min-width: 1420px) {
        line-height: 38px;
    }
`;

export const DivLineSC = styled.div`
    width: 100%;
    min-width: 320px;
    height: 1px;
    box-shadow: 0px 0px 6px #a557e5;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
`;

export const DivCustomArrowRight = styled(CgChevronRight)`
    width: 4vw;
    height: 4vw;
    position: absolute;
    right: 0;
    z-index: 100;
    cursor: pointer;
    padding-bottom: 100px;
    align-self: center;
    @media (max-width: 768px) {
        height: 4vw;
        width: 4vw;
        padding-bottom: 80px;
    }
    @media (max-width: 480px) {
        height: 5vw;
        width: 5vw;
    }
    @media (max-width: 360px) {
        right: 3px;
    } ;
`;

export const MarginArrow = styled.div`
    @media (max-width: 769px) {
        margin-left: 25px;
    } ;
`;
export const DivCustomArrowLeft = styled(CgChevronLeft)`
    width: 4vw;
    height: 4vw;
    position: absolute;
    left: 0;
    display: grid;
    align-content: center;
 z-index: 100;
    cursor: pointer;
    padding-bottom: 100px;
    @media (max-width: 768px) {
        height: 4vw;
        width: 4vw;
        padding-bottom: 80px;
    }
    @media (max-width: 480px) {
        height: 5vw;
        width: 5vw;
    }
    @media (max-width: 360px) {
    } ;
`;

export const DivItemSC = styled.div`
    display: grid;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 14px;
    justify-content: center;
    justify-items: center;

    @media (max-width: 480px) {
        grid-row-gap: 5px;
    }
`;
export const DivItemBigSC = styled.div`
    display: grid;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 14px;
    justify-content: center;
    justify-items: center;
    margin-top: 30px;

    @media (max-width: 480px) {
        grid-row-gap: 5px;
    }
`;
export const DivItemPhotoSC = styled.div`
    background-image: ${({ Photo }) => Photo && `url(${Photo})`};
    width: 180px;
    height: 180px;
    background-size:100%;
    border-radius: 100%;
//background-repeat:no-repeat;
background-position:center;
    @media (max-width: 890px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 768px) {
        width: 180px;
        height: 180px;
    }
    @media (max-width: 660px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 550px) {
        width: 120px;
        height: 120px;
    }
    @media (max-width: 450px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 360px) {
        width: 120px;
        height: 120px;
    }
`;

export const DivItemPhotoBigSC = styled.div`
    background-image: ${({ Photo }) => Photo && `url(${Photo})`};
    width: 180px;
    height: 180px;
    background-size:100%;
    border-radius: 100%;
    margin-top: 30px;
//background-repeat:no-repeat;
background-position:center;
    @media (max-width: 890px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 768px) {
        width: 180px;
        height: 180px;
    }
    @media (max-width: 660px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 550px) {
        width: 120px;
        height: 120px;
    }
    @media (max-width: 450px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 360px) {
        width: 120px;
        height: 120px;
    }
`;
export const DivItemNameSC = styled.div`
    font-family: "Orbitron";
    display: grid;
    justify-content: center;
    justify-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    @media (min-width: 360px) {
        font-size: 10px;
        line-height: 18px;
    }
    @media (min-width: 768px) {
        font-size: 14px;
        line-height: 25px;
    }
    @media (max-width: 360px) {
        font-size: 10px;
        line-height: 18px;
    }
`;
export const DivDesignCustomArrowRight = styled(CgChevronRight)`
    padding-bottom: 3vw;
    width: 4vw;
    height: 4vw;
    position: absolute;
    bottom: 50;
    right: 0;
    padding-bottom: 110px;

    @media (max-width: 768px) {
        height: 4vw;
        width: 4vw;
        padding-bottom: 44px;
    }
    @media (max-width: 480px) {
        height: 5vw;
        width: 5vw;
    }
    @media (max-width: 360px) {
        right: 3px;
    } ;
`;
export const DivDesignCustomArrowLeft = styled(CgChevronLeft)`
    width: 4vw;
    height: 4vw;
    position: absolute;
    left: 0;
    display: grid;
    align-content: center;
    padding-bottom: 110px;

    @media (max-width: 768px) {
        height: 4vw;
        width: 4vw;
        padding-bottom: 44px;
    }
    @media (max-width: 480px) {
        height: 5vw;
        width: 5vw;
    }
    @media (max-width: 360px) {
    } ;
`;