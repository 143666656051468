import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  DivItemSC,
  DivItemPhotoSC,
  DivItemNameSC,
} from "../../styles/section3.styles";
import {
  DivCustomArrowLeft,
  DivCustomArrowRight,
  DivDesignCustomArrowRight,
  DivDesignCustomArrowLeft,
} from "../../styles/section3.styles";

import Sam from "../../images/Sam.png";
import Denis from "../../images/Denis.png";
import Ian from "../../images/Ian.png";
import Kirill from "../../images/Kirill.png";
import Ruslan from "../../images/Ruslan.png";
import Ilya from "../../images/Ilya.png";
import Andrey from "../../images/Andrey.png";
import Irene from "../../images/Irene.png";
import Dmitry from "../../images/Dmitry.png";
import Stas from "../../images/Stas.png";
import Sveta from "../../images/Sveta.png";
import Rob from "../../images/Rob.png";
import Lisa from "../../images/Lisa.png";
import Maria from "../../images/Maria.bmp";
import LazyShowLeft from "../../LazyShowLeft";
import LazyShowRight from "../../LazyShowRight";
/* import NoPhoto from "../images/NoPhoto.png"; */

const AboutTeamDeveloperCarousel = ({ indexSelectedButton }) => {
  // console.log("indexSelectedButton",indexSelectedButton);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1601 },
      items: 6,
      slidesToSlide: 0,
    },
    PC: {
      breakpoint: { max: 1600, min: 1331 },

      items: 6,
      slidesToSlide: 1,
    },
    MiniPC: {
      breakpoint: { max: 1330, min: 1101 },

      items: 5,
      slidesToSlide: 1,
    },
    Desctop: {
      breakpoint: { max: 1100, min: 891 },

      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 890, min: 769 },

      items: 4,
      slidesToSlide: 1,
    },
    smallmobile: {
      breakpoint: { max: 768, min: 451 },
      items: 3,
      slidesToSlide: 1,
    },
    verysmallmobile: {
      breakpoint: { max: 450, min: 240 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <DivCustomArrowRight onClick={() => onClick()} />;
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <DivCustomArrowLeft onClick={() => onClick()} />;
  };

  const CustomDesignRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <DivDesignCustomArrowRight onClick={() => onClick()} />;
  };

  const CustomDesignLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <DivDesignCustomArrowLeft onClick={() => onClick()} />;
  };
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );
  return (
    <>
      {indexSelectedButton === 0 ? (
        <>
          {isSafari ? (
            <>
              <Carousel
                indexSelectedButton={indexSelectedButton}
                containerClass="carousel-containerTeamDevDesign"
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                itemClass="carousel-itemTeamDevDesign"
                sliderClass="carousel-sliderTeamDevDesign"
              >
                <DivItemSC>
                  <DivItemPhotoSC Photo={Sam} />
                  <DivItemNameSC>
                    Sam Singer
                    <br />
                    Founder and Owner
                  </DivItemNameSC>
                </DivItemSC>
                <DivItemSC>
                  <DivItemPhotoSC
                    Photo={Denis}
                    indexSelectedButton={indexSelectedButton}
                  />
                  <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                    Den Gordin <br />
                    Project-manager
                  </DivItemNameSC>
                </DivItemSC>
              </Carousel>
            </>
          ) : (
            <>
              {" "}
              <Carousel
                indexSelectedButton={indexSelectedButton}
                containerClass="carousel-containerTeamDevDesign"
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                itemClass="carousel-itemTeamDevDesign"
                sliderClass="carousel-sliderTeamDevDesign"
              >
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Sam} />
                    <DivItemNameSC>
                      Sam Singer
                      <br />
                      Founder and Owner
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC
                      Photo={Denis}
                      indexSelectedButton={indexSelectedButton}
                    />
                    <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                      Den Gordin <br />
                      Project-manager
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
              </Carousel>
            </>
          )}
        </>
      ) : null}

      {indexSelectedButton === 1 ? (
        <>
          {isSafari ? (
            <>
              <Carousel
                indexSelectedButton={indexSelectedButton}
                containerClass="carousel-containerTeamDev"
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                itemClass="carousel-itemTeamDev"
                sliderClass="carousel-sliderTeamDev"
              >
                <DivItemSC>
                  <DivItemPhotoSC Photo={Sam} />
                  <DivItemNameSC>
                    Sam Singer
                    <br />
                    Full-stack Engineer
                    <br /> Blockchain Architect
                    <br /> System Security
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC Photo={Kirill} />
                  <DivItemNameSC>
                    Kirill Drozdov
                    <br />
                    Full-stack Developer
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC Photo={Ruslan} />
                  <DivItemNameSC>
                    Ruslan Gordin
                    <br />
                    Frontend Developer
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC Photo={Ilya} />
                  <DivItemNameSC>
                    Ilya Morozov
                    <br />
                    Backend Developer
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC Photo={Sveta} />
                  <DivItemNameSC>
                    Svetlana Soboleva
                    <br />
                    Frontend Developer
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC Photo={Stas} />
                  <DivItemNameSC>
                    Stanislav Maximov
                    <br />
                    Backend Developer
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC Photo={Dmitry} />
                  <DivItemNameSC>
                    Dmitry Galtsov
                    <br />
                    Frontend Developer
                  </DivItemNameSC>
                </DivItemSC>
              </Carousel>
            </>
          ) : (
            <>
              <Carousel
                indexSelectedButton={indexSelectedButton}
                containerClass="carousel-containerTeamDev"
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                itemClass="carousel-itemTeamDev"
                sliderClass="carousel-sliderTeamDev"
              >
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Sam} />
                    <DivItemNameSC>
                      Sam Singer
                      <br />
                      Full-stack Engineer
                      <br /> Blockchain Architect
                      <br /> System Security
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Kirill} />
                    <DivItemNameSC>
                      Kirill Drozdov
                      <br />
                      Full-stack Developer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Ruslan} />
                    <DivItemNameSC>
                      Ruslan Gordin
                      <br />
                      Frontend Developer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
                <LazyShowRight>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Ilya} />
                    <DivItemNameSC>
                      Ilya Morozov
                      <br />
                      Backend Developer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowRight>
                <LazyShowRight>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Sveta} />
                    <DivItemNameSC>
                      Svetlana Soboleva
                      <br />
                      Frontend Developer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowRight>
                <LazyShowRight>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Stas} />
                    <DivItemNameSC>
                      Stanislav Maximov
                      <br />
                      Backend Developer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowRight>
                <LazyShowRight>
                  <DivItemSC>
                    <DivItemPhotoSC Photo={Dmitry} />
                    <DivItemNameSC>
                      Dmitry Galtsov
                      <br />
                      Frontend Developer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowRight>
              </Carousel>
            </>
          )}
        </>
      ) : null}

      {indexSelectedButton === 2 ? (
        <>
          {isSafari ? (
            <>
              <Carousel
                indexSelectedButton={indexSelectedButton}
                containerClass="carousel-containerTeamDevDesign"
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                itemClass="carousel-itemTeamDevDesign"
                sliderClass="carousel-sliderTeamDevDesign"
              >
                <DivItemSC>
                  <DivItemPhotoSC
                    Photo={Andrey}
                    indexSelectedButton={indexSelectedButton}
                  />
                  <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                    Andrey Baykov
                    <br />
                    UI/UX Designer
                  </DivItemNameSC>
                </DivItemSC>
                <DivItemSC>
                  <DivItemPhotoSC
                    Photo={Irene}
                    indexSelectedButton={indexSelectedButton}
                  />
                  <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                    Irene Gordina
                    <br />
                    UI/UX Designer
                  </DivItemNameSC>
                </DivItemSC>
                <DivItemSC>
                  <DivItemPhotoSC
                    Photo={Lisa}
                    indexSelectedButton={indexSelectedButton}
                  />
                  <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                    Lisa Raklova
                    <br />
                    UI/UX Designer
                  </DivItemNameSC>
                </DivItemSC>

                <DivItemSC>
                  <DivItemPhotoSC
                    Photo={Maria}
                    indexSelectedButton={indexSelectedButton}
                  />
                  <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                    Maria Kuzakova
                    <br />
                    3D-Modeller
                  </DivItemNameSC>
                </DivItemSC>
              </Carousel>
            </>
          ) : (
            <>
              <Carousel
                indexSelectedButton={indexSelectedButton}
                containerClass="carousel-containerTeamDevDesign"
                responsive={responsive}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                itemClass="carousel-itemTeamDevDesign"
                sliderClass="carousel-sliderTeamDevDesign"
              >
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC
                      Photo={Andrey}
                      indexSelectedButton={indexSelectedButton}
                    />
                    <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                      Andrey Baykov
                      <br />
                      UI/UX Designer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
                <LazyShowLeft>
                  <DivItemSC>
                    <DivItemPhotoSC
                      Photo={Irene}
                      indexSelectedButton={indexSelectedButton}
                    />
                    <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                      Irene Gordina
                      <br />
                      UI/UX Designer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowLeft>
                <LazyShowRight>
                  <DivItemSC>
                    <DivItemPhotoSC
                      Photo={Lisa}
                      indexSelectedButton={indexSelectedButton}
                    />
                    <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                      Lisa Raklova
                      <br />
                      UI/UX Designer
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowRight>
                <LazyShowRight>
                  <DivItemSC>
                    <DivItemPhotoSC
                      Photo={Maria}
                      indexSelectedButton={indexSelectedButton}
                    />
                    <DivItemNameSC indexSelectedButton={indexSelectedButton}>
                      Maria Kuzakova
                      <br />
                      3D-Modeller
                    </DivItemNameSC>
                  </DivItemSC>
                </LazyShowRight>
              </Carousel>
            </>
          )}
        </>
      ) : null}
      {/* {indexSelectedButton === 3 ? (
                <Carousel
                    indexSelectedButton={indexSelectedButton}
                    containerClass="carousel-containerTeamDevLeadership"
                    responsive={responsive}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    itemClass="carousel-itemTeamDevLeadership"
                    sliderClass="carousel-sliderTeamDevLeadership"
                >
                    <DivItemSC>
                        <DivItemPhotoSC
                            Photo={NoPhoto}
                            indexSelectedButton={indexSelectedButton}
                        />
                        <DivItemNameSC
                            indexSelectedButton={indexSelectedButton}
                        >
                            Name<p>lorem ipsum</p>
                        </DivItemNameSC>
                    </DivItemSC>

                    <DivItemSC>
                        <DivItemPhotoSC
                            Photo={NoPhoto}
                            indexSelectedButton={indexSelectedButton}
                        />
                        <DivItemNameSC
                            indexSelectedButton={indexSelectedButton}
                        >
                            Name<p>lorem ipsum</p>
                        </DivItemNameSC>
                    </DivItemSC>
                </Carousel>
            ) : null} */}
    </>
  );
};

export default AboutTeamDeveloperCarousel;
