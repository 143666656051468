import  styled  from 'styled-components/macro';

export const DivGlitchSC = styled.div`
  position: relative;
	 /* color: #fff; */
	 /* font-size: 80px; */
	 /* font-weight: 900; */
    /* font-size: 26px; */
    font-size: ${({fontSize}) => (fontSize)};
    letter-spacing: ${({literSpacing}) => (literSpacing)};
    background: var(--blue-pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({color}) => (color)};
    background-clip: text;
    font-family: ${({fontFamily}) => (fontFamily)};
    @media (min-width: 680px) {
	
		

      font-size: ${({fontSize}) => (fontSize)};
		/* line-height: clamp(33px, 3.5vw, 85px); */
    /* line-height: 59px; */

	
		
}
`