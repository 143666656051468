import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CgChevronRight, CgChevronLeft } from "react-icons/cg";

import {
    DivContainerAboutDevSC,
    DivDecriptionTextSC,
    GridContainerItemsSC,
    DivCustomTextSC,
    GridRowsIcons,
    ImgIconSC,
    GridColumnsIcons,
    GridColumnsSecondIcons,
    GridIconsSC,
    CarouselHidden,
    DivCustomArrowRight,
    DivCustomArrowLeft,
} from "../../styles/styled-section4";

import AdobeIllustrator from "../../images/DevIcons/AdobeIllustrator.svg";
import Blender from "../../images/DevIcons/Blender.svg";
import Remix from "../../images/DevIcons/Remix.svg";
import Photoshop from "../../images/DevIcons/Photoshop.svg";
import AfterEffects from "../../images/DevIcons/AfterEffects.svg";
import EtherJS from "../../images/DevIcons/EtherJS.svg";
import Express from "../../images/DevIcons/Express.svg";
import Figma from "../../images/DevIcons/Figma.svg";
import HardHat from "../../images/DevIcons/HardHat.svg";
import HTML5 from "../../images/DevIcons/HTML5.svg";
import JS from "../../images/DevIcons/JS.svg";
import METAMASK from "../../images/DevIcons/METAMASK.svg";
import MySQL from "../../images/DevIcons/MySQL.svg";
import node from "../../images/DevIcons/node.svg";
import PostgreSQL from "../../images/DevIcons/PostgreSQL.svg";
import PyTeAl from "../../images/DevIcons/PyTeAl.svg";
import Python from "../../images/DevIcons/Python.svg";
import ReactIcon from "../../images/DevIcons/React.svg";
import Sass from "../../images/DevIcons/Sass.svg";
import Selenium from "../../images/DevIcons/Selenium.svg";
import Solidity from "../../images/DevIcons/Solidity.svg";
import TRUFFLE from "../../images/DevIcons/TRUFFLE.svg";
import web3 from "../../images/DevIcons/web3.svg";
import OpenZeppelin from "../../images/DevIcons/OpenZeppelin.svg";
import Rust from "../../images/DevIcons/Rust.svg";
import Substrate from "../../images/DevIcons/Substrate.svg";

const AboutDevCarousel = ({ props }) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1420 },
            items: 7,
            slidesToSlide: 7,
        },
        desktop: {
            breakpoint: { max: 1600, min: 1300 },
            items: 6,
            slidesToSlide: 6,
        },
        smalldesktop: {
            breakpoint: { max: 1300, min: 1000 },
            items: 5,
            slidesToSlide: 5,
        },
        tablet: {
            breakpoint: { max: 1000, min: 768 },
            items: 4,
            slidesToSlide: 4,
        },
        smalltablet: {
            breakpoint: { max: 768, min: 650 },
            items: 5,
            slidesToSlide: 5,
        },
        mobile: {
            breakpoint: { max: 650, min: 480 },
            items: 4,
            slidesToSlide: 4,
        },
        smallmobile: {
            breakpoint: { max: 480, min: 360 },
            items: 3,
            slidesToSlide: 3,
        },
        mini: {
            breakpoint: { max: 360, min: 120 },
            items: 2,
            slidesToSlide: 2,
        },
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType },
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <DivCustomArrowRight onClick={() => onClick()} />;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType },
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <DivCustomArrowLeft onClick={() => onClick()} />;
    };
    return (
        <>
            <Carousel
                infinite={true}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                sliderClass="carousel-slider"
                itemClass="carousel-item"
                containerClass="carousel-container"
                responsive={responsive}
            >
                <ImgIconSC Photo={web3} />
                <ImgIconSC Photo={METAMASK} />
                <ImgIconSC Photo={Solidity} />
                <ImgIconSC Photo={HTML5} />
                <ImgIconSC Photo={Rust} />
                <ImgIconSC Photo={Substrate} />
                <ImgIconSC Photo={node} />
                <ImgIconSC Photo={HardHat} />
                <ImgIconSC Photo={OpenZeppelin} />
                <ImgIconSC Photo={Python} />
                <ImgIconSC Photo={MySQL} />
                <ImgIconSC Photo={PyTeAl} />
                <ImgIconSC Photo={AdobeIllustrator} />
                <ImgIconSC Photo={TRUFFLE} />
                <ImgIconSC Photo={Express} />
                <ImgIconSC Photo={Sass} />
                <ImgIconSC Photo={ReactIcon} />
                <ImgIconSC Photo={JS} />
                <ImgIconSC Photo={PostgreSQL} />
                <ImgIconSC Photo={EtherJS} />
                <ImgIconSC Photo={Selenium} />
                <ImgIconSC Photo={Figma} />
                <ImgIconSC Photo={AfterEffects} />
                <ImgIconSC Photo={Blender} />
                <ImgIconSC Photo={Remix} />
                <ImgIconSC Photo={Photoshop} />
            </Carousel>
        </>
    );
};

export default AboutDevCarousel;
