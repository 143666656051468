import React, { useEffect, useState, useRef } from "react";
import "../../../styles/git-button.css";
import { Stage, Layer, Circle } from "react-konva";
import Konva from "konva";

const Loading = ({ containerId }) => {
	const [width, setWidth] = useState(100);
	const [height, setHeight] = useState(100);
	const [widthC, setWidthC] = useState(window.innerWidth);
	const [strokeWidth, setStrokeWidth] = useState();
	const [sizeIn, setSizeIn] = useState();
	const [sizeOut, setSizeOut] = useState();
	const [outSideCircleArray, setOutSideCircleArray] = useState();
	const [circle, setCircle] = useState(null);
	const outSideCircleRef = useRef();
	const inSideCircleRef = useRef();
	const handleWindowSizeChange = (e) => {
		setWidthC(e.target.innerWidth);
		let _widthC = e.target.innerWidth
		console.log('><><>>>!!@', widthC,
		widthC <= 550, widthC <= 768, widthC > 768);
		if (_widthC <= 550) {
			setSizeIn(25);
			setSizeOut(15);
			setStrokeWidth(3);
			setWidth(65)
			setHeight(65)
			setOutSideCircleArray([5, 10, 55, 10, 10, 8]);
		} else if (_widthC <= 768) {
			setSizeIn(35);
			setSizeOut(22);
			setStrokeWidth(4);
			setWidth(85)
			setHeight(85)
			setOutSideCircleArray([5, 15, 85, 5, 10, 5]);

		} else if (_widthC > 768) {
			setSizeIn(45);
			setSizeOut(30);
			setStrokeWidth(5);
			setWidth(100)
			setHeight(100)
			setOutSideCircleArray([125, 20, 15, 5, 15, 10, 10, 5, 4])
		}
	};

	useEffect(() => {
		let e = {
			target: {
				innerWidth: window.innerWidth
			}
		}
		handleWindowSizeChange(e);
		window.onresize = handleWindowSizeChange;
	}, [window.innerWidth]);

	

	useEffect(() => {
		let anim = null;
		let period = 20;

		anim = new Konva.Animation(
			(frame) => {
				var turnRight = (frame.timeDiff * period) / 800;
				var leftRight = -(frame.timeDiff * period) / 1300;
				if (outSideCircleRef.current && inSideCircleRef.current) {
					outSideCircleRef.current.rotate(turnRight);
					inSideCircleRef.current.rotate(leftRight);
				}
			},
			outSideCircleRef.current.getLayer(),
			inSideCircleRef.current.getLayer()
		);
		anim.start();
		return () => {
			anim.stop();
			anim = null;
		};
	}, [outSideCircleRef, inSideCircleRef]);

	// useEffect(() => {
	// 	var offsetHeight = document.getElementById(containerId).offsetHeight;
	// 	var offsetWidth = document.getElementById(containerId).offsetWidth;

	// 	setWidth(offsetWidth);
	// 	setHeight(offsetHeight);
	// }, [window.innerWidth]);

	//   var angularSpeed = 90;
	//   var anim = new Konva.Animation(function (frame) {
	//     var angleDiff = (frame.timeDiff * angularSpeed) / 1000;
	//     blueRect.rotate(angleDiff);
	//     yellowRect.rotate(angleDiff);
	//     redRect.rotate(angleDiff);
	//   }, layer);
	console.log('><><>>>!!@', width, height);
	return (
		<>
			<Stage width={width} height={height}>
				<Layer>
					<Circle
						key={"circle-button-1"}
						ref={outSideCircleRef}
						x={width / 2}
						y={height / 2}
						radius={sizeOut}
						stroke={"#D516FF"}
						dash={outSideCircleArray}
						shadowColor={"#D516FF"}
						shadowBlur={5}
						strokeWidth={strokeWidth - 1}
					/>
					<Circle
						key={"circle-button-2"}
						ref={inSideCircleRef}
						x={width / 2}
						y={height / 2}
						radius={sizeIn}
						stroke={"#D516FF"}
						strokeWidth={strokeWidth}
						dash={[40, 10, 90, 20, 5, 10, 30]}
						shadowColor={"#D516FF"}
						shadowBlur={5}
					/>
				</Layer>
			</Stage>
		</>
	);
};

export default Loading;
