import React, { useContext, useState, useEffect, useLayoutEffect } from "react";
import {
    DivFooterMailSC,
    DivFooterWrapSC,
    DivGitContainerSC,
    DivMailBoxSC,
    DivBoxImailSC,
} from "../../styles/styles.footer";
import CyberModal from "../footer/components/Modal";

import Loading from "./components/Loading";
import { FiMail } from "react-icons/fi";
const Footer = () => {
    const [isOpen, setIsOpen] = useState(false);
    console.log(isOpen);
    const OpenModal = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    };
    return (
        <DivFooterWrapSC>
            <DivFooterMailSC
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClick={OpenModal}
            >
                <DivGitContainerSC id="gitButton">
                    <Loading containerId={"gitButton"} />
                    <DivMailBoxSC>
                        <DivBoxImailSC>
                            <FiMail size={"100%"} color="#D516FF" />
                        </DivBoxImailSC>
                    </DivMailBoxSC>
                </DivGitContainerSC>
            </DivFooterMailSC>
            <CyberModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </DivFooterWrapSC>
    );
};

export default Footer;
