import React, { useState } from "react";
import {
  DivContainerBoxSC,
  DivFormBoxSC,
  DivFormDataBoxSC,
  DivInputBoxSC,
  H1TitleBoxSC,
  InputSC,
  LabelInputSC,
  LinkNavBarBoxSC,
  LogoNavBarSC,
  TextareaSC,
  TextNavBarSC,
  SpanBudgetSC,
  DivBoxColumnsMoneySC,
  DivBoxTextSC,
  TeaxAreaSC,
  LabelTeaxtAreaSC,
  ButtonsSendCS,
} from "../../styles/styled.brief";
import InputMask from "react-input-mask";
const UIUXDesignBreaft = () => {
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [focusedPhone, setFocusedPhone] = useState("");
  const [focusedBudget, setFocusedBudget] = useState("");

  const handlePhoneInput = ({ target }) => {
    setFocusedPhone(target.id);
  };

  const handleBudgetInput = ({ target }) => {
    setFocusedBudget(target.id);
  };
  const [terms, setTerms] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [terms3, setTerms3] = useState(false);
  const [terms4, setTerms4] = useState(false);
  const [website, setwebsite] = useState(false);
  const [mobileApp, setMobileApp] = useState(false);

  const Terms = (e) => {
      
    setTerms(e.target.value);
};
  const Terms2 = () => {
    setTerms2(!terms2);
  };
  const Terms3 = () => {
    setTerms3(!terms3);
  };
  const Terms4 = () => {
    setTerms4(!terms4);
  };
  const MobileApp = () => {
    setMobileApp(!mobileApp);
  };
  console.log(website);

  return (
    <>
   <DivInputBoxSC>
        <DivBoxTextSC>Specify the platforms for which the application is needed</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed1"
              value={"iOS"}
            />

            <SpanBudgetSC className="text1">
              iOS
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed2"
              value={"Android"}
            />

            <SpanBudgetSC className="text">
              Android
            </SpanBudgetSC>
          </label>

        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
 <DivInputBoxSC>
        <DivBoxTextSC>Specify the platforms for which the application is needed</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed1"
              value={"iOS (iPhone)"}
            />

            <SpanBudgetSC className="text1">
              iOS (iPhone)
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed2"
              value={"iOS (iPad)"}
            />

            <SpanBudgetSC className="text1">
              iOS (iPad)
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed3"
              value={"Android (phone)"}
            />

            <SpanBudgetSC className="text1">
              Android (phone)
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Specify the platforms for which the application is needed4"
              value={"Android (tablet)"}
            />

            <SpanBudgetSC className="text1">
              Android (tablet)
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>App type</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
     
    <label className="label2">
      <input
        type="radio"
        className="radio"
        name="App type"
        value={"Functional application"}
        checked={terms == 'Functional application' ? true : false}
                            onChange={Terms}
      />
      <span className="fake2"></span>
      <SpanBudgetSC className="text1">
      Functional application
      </SpanBudgetSC>
    </label>
    <label className="label2">
      <input
        type="radio"
        className="radio"
        name="App type"
        value={"Client-server application"}
        checked={terms == 'Client-server application' ? true : false}
                            onChange={Terms}
      />
      <span className="fake2"></span>
      <SpanBudgetSC className="text1">
      Client-server application
      </SpanBudgetSC>
    </label>
    
    <label className="label2">
      <input
      value={"AnotherTypeApp"}
        type="radio"
        className="radio"
        name="App type"
        checked={terms == 'AnotherTypeApp' ? true : false}
                            onChange={Terms}
                            
      />
      <span className="fake2"></span>
      <SpanBudgetSC className="text1">
      Another
      </SpanBudgetSC>
    </label>
    {terms=="AnotherTypeApp"?(<InputSC
                        name="AnotherTypeApp"
                        type={"text"}
                        id="AnotherTypeApp"
                       
                    />):null}
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="App type and web3"
              value={"Web3 & Blockchain"}
            />

            <SpanBudgetSC className="text1">
              Web3 & Blockchain
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Is there a need to create an application management system?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Is it necessary to develop a promotional website?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Is it necessary to develop a promotional website?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Distribution method</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution method"
              value={"Free of charge"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Free of charge
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution method"
              value={"Paid"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Paid
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Monetization method</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Monetization method"
              value={"Internal advertising"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Internal advertising
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Monetization method"
              value={"In-app purchases"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            In-app purchases
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Distribution Account</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution Account"
              value={"Developer Account"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Developer Account
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Distribution Account"
              value={"Own account"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Own account
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>      
      <DivInputBoxSC>
        <DivBoxTextSC>Updating the app content</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Updating the app content"
              value={"There is a server part"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            There is a server part
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Updating the app content"
              value={"You will need to create a server part"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            You will need to create a server part
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Updating the app content"
              value={"Manually"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            Manually
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Notifications</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
        <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Notifications1"
              value={"Push notifications"}
            />

            <SpanBudgetSC className="text1">
            Push notifications
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Notifications2"
              value={"SMS"}
            />

            <SpanBudgetSC className="text1">
            SMS
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Will users have a "personal account" in the app?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will users have a personal account in the app?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will users have a personal account in the app?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Will there be authorization via social networks?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>

          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will there be authorization via social networks?"
              value={"Yes"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              Yes
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="Will there be authorization via social networks?"
              value={"No"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
              No
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What languages are planned in the mobile app?</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
        <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app1?"
              value={"English"}
            />

            <SpanBudgetSC className="text1">
            English
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app2?"
              value={"German"}
            />

            <SpanBudgetSC className="text1">
            German
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app3?"
              value={"French"}
            />

            <SpanBudgetSC className="text1">
            French
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app4?"
              value={"Italian"}
            />

            <SpanBudgetSC className="text1">
            Italian
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app5?"
              value={"Russian"}
            />

            <SpanBudgetSC className="text1">
            Russian
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app6?"
              value={"Spanish"}
            />

            <SpanBudgetSC className="text1">
            Spanish
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What languages are planned in the mobile app7?"
              value={"Other"}
              onChange={Terms2}
              isChecked={terms2}
            />

            <SpanBudgetSC className="text1">
            Other
            </SpanBudgetSC>
          </label>
          {terms2 ? (<InputSC
            name="What languages are planned in the mobile app"
            type={"text"}
            id="What languages are planned in the mobile app"

          />) : null}
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Whether you need a mobile version of the site or adaptive design</DivBoxTextSC>
        <DivBoxColumnsMoneySC>
        <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Whether you need a mobile version of the site or adaptive design1"
              value={"No, not needed"}
            />

            <SpanBudgetSC className="text1">
            No, not needed
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Whether you need a mobile version of the site or adaptive design2"
              value={"Need a mobile version of the site"}
            />

            <SpanBudgetSC className="text1">
            Need a mobile version of the site
            </SpanBudgetSC>
          </label>

          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Whether you need a mobile version of the site or adaptive design3"
              value={"Adaptive design"}
            />

            <SpanBudgetSC className="text1">
            Adaptive design
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
      </DivInputBoxSC>
      <DivInputBoxSC>
            <DivBoxTextSC >App functions</DivBoxTextSC>
          
            <TeaxAreaSC
                name="App functions"
                type={"text"}
                id="App functions"
                placeholder="Describe what functions the application should perform"
            />
        </DivInputBoxSC>
        <DivInputBoxSC>
            <DivBoxTextSC >App structure</DivBoxTextSC>
            <label className="label2">
            <input
              type="radio"
              className="radio"
              name="App structure"
              value={"The application structure is developed by the contractor"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            The application structure is developed by the contractor
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="radio"
              className="radio"
              name="App structure"
              value={"The structure of the application is provided by the customer"}
            />
            <span className="fake2"></span>
            <SpanBudgetSC className="text1">
            The structure of the application is provided by the customer
            </SpanBudgetSC>
          </label>
          <DivBoxTextSC>Navigating the app</DivBoxTextSC>
          <TeaxAreaSC
                name="Navigating the app"
                type={"text"}
                id="App functions"
                placeholder="Which items should contain the menu"
            />
        </DivInputBoxSC>
        
        <DivInputBoxSC>
            <DivBoxTextSC >App design</DivBoxTextSC>
            <DivBoxTextSC></DivBoxTextSC>
            <DivBoxTextSC>What elements of corporate identity exist and can be used in design development</DivBoxTextSC>
            <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development1"
              value={"Brand Name Guide"}
            />

            <SpanBudgetSC className="text1">
            Brand Name Guide
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development2"
              value={"logo"}
            />

            <SpanBudgetSC className="text">
            logo
            </SpanBudgetSC>
          </label>
      
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development3"
              value={"Branded colors"}
            />

            <SpanBudgetSC className="text">
            Branded colors
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development4"
              value={"Branded fonts"}
            />

            <SpanBudgetSC className="text">
            Branded fonts
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development5"
              value={"Polygraphy"}
            />

            <SpanBudgetSC className="text">
            Polygraphy
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development6"
              value={"Photos"}
            />

            <SpanBudgetSC className="text">
            Photos
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development7"
              value={"Catalogs"}
            />

            <SpanBudgetSC className="text">
            Catalogs
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="What elements of corporate identity exist and can be used in design development8"
              value={"Booklets"}
            />

            <SpanBudgetSC className="text">
            Booklets
            </SpanBudgetSC>
          </label>
          <label className="label2">
      <input
        type="checkbox"
        className="radio2"
        value={"AnotherDesingApp"}
        onChange={Terms4}
        isChecked={terms4}
      />
      
      <SpanBudgetSC className="text1">
      Another
      </SpanBudgetSC>
    </label>
    {terms4?(<InputSC
                        name="AnotherDesingApp"
                        type={"text"}
                        id="AnotherDesingApp"
                       
                    />):null}
    
        </DivBoxColumnsMoneySC>
        
        </DivInputBoxSC>

        <DivInputBoxSC>
            <DivBoxTextSC >The mood and associations the app design should evoke</DivBoxTextSC>
            <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="The mood and associations the app design should evoke1"
              value={"Rigorous corporate design"}
            />

            <SpanBudgetSC className="text1">
            Rigorous corporate design
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="The mood and associations the app design should evoke2"
              value={"Bright, catchy design"}
            />

            <SpanBudgetSC className="text">
            Bright, catchy design
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="The mood and associations the app design should evoke3"
              value={"Positive and fun"}
            />

            <SpanBudgetSC className="text">
            Positive and fun
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="The mood and associations the app design should evoke4"
              value={"Illustration/Photo Rich Design"}
            />

            <SpanBudgetSC className="text">
            Illustration/Photo Rich Design
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="The mood and associations the app design should evoke5"
              value={"Minimalist design. Emphasis on functionality"}
            />

            <SpanBudgetSC className="text">
            Minimalist design. Emphasis on functionality
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="The mood and associations the app design should evoke6"
              value={"Polygraphy"}
            />

            <SpanBudgetSC className="text">
            Polygraphy
            </SpanBudgetSC>
          </label>
          <label className="label2">
      <input
        type="checkbox"
        className="radio2"
         onChange={Terms3}
        isChecked={terms3}
        
      />
      
      <SpanBudgetSC className="text1">
      Another
      </SpanBudgetSC>
    </label>
  {terms3?(<InputSC
                        name="AnotherAppDesing"
                        type={"text"}
                        id="AnotherAppDesing"
                       
                    />):null}
        </DivBoxColumnsMoneySC>
        
        </DivInputBoxSC>

        <DivInputBoxSC>
            <DivBoxTextSC >Will it be used in the app:</DivBoxTextSC>
            <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Will it be used in the app:1"
              value={"3D-design"}
            />

            <SpanBudgetSC className="text1">
            3D-design
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Will it be used in the app:2"
              value={"Animation"}
            />

            <SpanBudgetSC className="text">
            Animation
            </SpanBudgetSC>
          </label>
          
        </DivBoxColumnsMoneySC>
        </DivInputBoxSC>

        <DivInputBoxSC>
            <DivBoxTextSC >Availability of photos and pictures for design development</DivBoxTextSC>
            <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Will it be used in the app:1"
              value={"Is available"}
            />

            <SpanBudgetSC className="text1">
            Is available
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Will it be used in the app:2"
              value={"You need to choose a designer"}
            />

            <SpanBudgetSC className="text">
            You need to choose a designer
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
        </DivInputBoxSC>
        <DivInputBoxSC>
            <DivBoxTextSC>Specify applications in a similar subject that you consider successful and describe why</DivBoxTextSC>
            <TeaxAreaSC
          name="Specify applications in a similar subject that you consider successful and describe why"
          type={"text"}
          id="Specify applications in a similar subject that you consider successful and describe why"
          placeholder="My answer"
        />
        </DivInputBoxSC>
        
        <DivInputBoxSC>
            <DivBoxTextSC>Indicate unsuccessful, in your opinion, applications in a similar subject and describe why</DivBoxTextSC>
            <TeaxAreaSC
          name="Indicate unsuccessful, in your opinion, applications in a similar subject and describe why"
          type={"text"}
          id="Indicate unsuccessful, in your opinion, applications in a similar subject and describe why"
          placeholder="My answer"
        />
        </DivInputBoxSC>

        <DivInputBoxSC>
            <DivBoxTextSC>The main requirements and wishes for the design of the site</DivBoxTextSC>
            <TeaxAreaSC
          name="The main requirements and wishes for the design of the site"
          type={"text"}
          id="The main requirements and wishes for the design of the site"
          placeholder="Here you can write any information that will help our designer"
        />
        </DivInputBoxSC>

        <DivInputBoxSC>
            <DivBoxTextSC >Content</DivBoxTextSC>
            <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Content1"
              value={"Have your own content"}
            />

            <SpanBudgetSC className="text1">
            Have your own content
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Content2"
              value={"Copywriter services required"}
            />

            <SpanBudgetSC className="text">
            Copywriter services required
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
        </DivInputBoxSC>

        <DivInputBoxSC>
            <DivBoxTextSC >Supplementary services</DivBoxTextSC>
            <DivBoxColumnsMoneySC>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Content3"
              value={"Logo Development"}
            />

            <SpanBudgetSC className="text1">
            Logo Development
            </SpanBudgetSC>
          </label>
          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Content4"
              value={"Corporate Identity Development"}
            />

            <SpanBudgetSC className="text">
            Corporate Identity Development
            </SpanBudgetSC>
          </label>

          <label className="label2">
            <input
              type="checkbox"
              className="radio2"
              name="Content5"
              value={"Creating a slogan"}
            />

            <SpanBudgetSC className="text">
            Creating a slogan
            </SpanBudgetSC>
          </label>
        </DivBoxColumnsMoneySC>
        </DivInputBoxSC>
        <DivInputBoxSC>
        <DivBoxTextSC>Specify any additional data that you consider necessary</DivBoxTextSC>
<TeaxAreaSC
            name="Specify any additional data that you consider necessary"
            type={"text"}
            id="OtherTypeSite"
           

          />
      </DivInputBoxSC>
 
      
      <DivInputBoxSC><ButtonsSendCS type="submit">Send a brief</ButtonsSendCS></DivInputBoxSC>
      </>
  );
};

export default UIUXDesignBreaft;