import React, { useEffect } from "react";
import { CgSmileNone } from "react-icons/cg";
import {
    DivCalendarBoxSC,
    DivCalendarSC,
    DivColumnsSC,
    DivDecLineSC,
    DivInnerContainerSC,
    DivLeftColumnSC,
    DivRightColumnSC,
    DivRowsSC,
    DivSection6BoxSC,
    DivShadowBoxSC,
    DivTitleSC,
    DivWriteToUsContainerSC,
    TitleBoxSC,
    H3Sc,
} from "../../styles/styles.section6";

const MainSection6 = (props) => {
    const { bottomRefContacts, calendarRef } = props;
    var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(
            !window["safari"] ||
                (typeof safari !== "undefined" &&
                    window["safari"].pushNotification)
        );
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://link.goso.tech/js/form_embed.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <>
            <DivSection6BoxSC>
                {/* <TitleBoxSC>
                    <H3Sc>Have a project? Lets talk</H3Sc>
                </TitleBoxSC>
                <DivCalendarSC ref={calendarRef}>
                    <DivShadowBoxSC isSafari={!isSafari} />
                    {window.innerWidth >= 480 ? (
                        <iframe
                            scrolling="no"
                            id="QL7NSe9P0buw6xhjDKqm_1671342418680"
                            src="https://link.goso.tech/widget/appointment/service/cyberzenlabs?group=cyberzenlabs"
                            style={{
                                width: "100vw",
                                maxWidth: "1720px",
                                border: "none",
                                overflow: "hidden",
                                zIndex: "10",
                            }}
                        ></iframe>
                    ) : (
                        <iframe
                            scrolling="no"
                            id="QL7NSe9P0buw6xhjDKqm_1671342418680"
                            src="https://link.goso.tech/widget/appointment/service/cyberzenlabs?group=cyberzenlabs"
                            style={{
                                width: "100vw",
                                border: "none",
                                overflow: "hidden",
                                zIndex: "10",
                            }}
                        ></iframe>
                    )}
                </DivCalendarSC> */}
                <DivWriteToUsContainerSC ref={bottomRefContacts}>
                    <DivInnerContainerSC>
                        <DivTitleSC>
                            WRITE TO US
                            <DivDecLineSC isSafari={isSafari} />
                        </DivTitleSC>
                        <DivRowsSC>
                            <DivColumnsSC>
                                <DivLeftColumnSC>WhatApp:</DivLeftColumnSC>
                                <DivRightColumnSC>
                                    +1 (305) 467 12 96
                                </DivRightColumnSC>
                            </DivColumnsSC>
                            <DivColumnsSC>
                                <DivLeftColumnSC>telegram:</DivLeftColumnSC>
                                <DivRightColumnSC>@samsinger369</DivRightColumnSC>
                            </DivColumnsSC>
                            <DivColumnsSC>
                                <DivLeftColumnSC>e-mail:</DivLeftColumnSC>
                                <DivRightColumnSC>
                                sam@earthy.tech
                                </DivRightColumnSC>
                            </DivColumnsSC>
                        </DivRowsSC>
                    </DivInnerContainerSC>
                </DivWriteToUsContainerSC>
            </DivSection6BoxSC>
        </>
    );
};

export default MainSection6;
