import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import Clip from "../images/Clip.svg";

export const DivContainerPenalsSC = styled.div`
  display: grid;
  width: 90vw;
  max-width: 1400px;
  height: max-content;
  margin: 0 auto;
  grid-template-columns: 50% 50%;
  grid-template-rows: max-content max-content;
  /* grid-row-gap: 50px; */
 // grid-column-gap: 5vw;
  margin-bottom: 30px;
 // border: solid green;
  @media (max-width: 910px) {
    grid-column-gap: 0px;
      grid-row-gap: 0px;
    display: grid;
    width: 70vw;
   
    // max-width: 1478px;
    height: 100%;
    // justify-self: center;
    grid-template-rows: 1fr 70px 70px 200px 80px;
    grid-template-columns: max-content;
    //  padding-left: 15px;
  }
   @media (max-width: 530px) {
      max-width: 90vw;
    width: 90vw;
  }
`;

export const DivContainerPenals2SC = styled.div`
  display: none;
  @media (max-width: 810px) {
    display: grid;
    width: 100%;
    max-width: 1478px;
    height: 100%;
    justify-self: center;
    grid-template-rows: 1fr 70px 70px 1fr 80px;
  }
`;
export const DivBoxRowsLeftPenalSC = styled.div`
  //max-width: 625px;
  width: max-content;
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content;
  row-gap: 50px;
  @media (max-width: 810px) {
    max-width: 100%;
  }
`;

export const DivBoxTextSC = styled.div`
  width: 100%;
  max-width: 259px;
  height: 10px;
  display: grid;
  align-self: end;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(14px,2vw,24px);
  line-height: 30px;

`;

export const DivBoxBorderSC = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  //max-width: 621px;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid #e0f5e9;
  //box-shadow: 0px 5px 8px #868791;
  background: initial;
  display: grid;
  align-self: end;
  align-items: end;
  align-content: end;
  color: #fff;
  font-family: "Orbitron";
  font-size: clamp(18px,2vw,24px);
  :focus {
    outline: none;
  }
`;
export const DivBoxBorder2SC = styled.textarea`
  /* border-top: none;
  border-left: none;
  border-right: none; */
  max-width: 621px;
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #e0f5e9;
  //box-shadow: 0px 5px 8px #868791;
  background: initial;
  display: grid;
  align-self: start;
  align-items: start;
  align-content: start;
  color: #fff;
  font-family: "Orbitron";
  :focus {
    outline: none;
  }
  @media (max-width: 810px) {
    max-width: 100%;
  }
`;

export const DivBoxPersonalDataSC = styled.div`
  display: grid;
  width: 80%;
  grid-template-rows: repeat(4, 50px);
  row-gap: 33px;
  // border:1px solid red;
  @media (max-width: 910px) {
    width: 70vw;
  }
     @media (max-width: 530px) {
    width: 90vw;
  }
`;

export const DivBoxPriceSC = styled.div`
  display: grid;
  width: 80%;
  grid-template-rows: 20px max-content;
  grid-column-start: 1;

  margin-top: 10px;
  @media (max-width: 910px) {
    grid-row-start: 4;
    width: 70vw; 
    margin-top: 30px;
     gap:10px;
  }
  @media (max-width: 530px) {
    width: 90vw;
  }
 // border:1px solid red;
 margin-top: 50px;

`;

export const DivBoxBorderBigInputSC = styled.textarea`
  border-top: none;
  border-left: none;
  border-right: none;
  resize: none;
  overflow: hidden;
  //max-width: 621px;
  width: 100%;
  //max-width: 621px;
  height: 100%;
  grid-column-start: 2;
  grid-row-start: 1;
  border-bottom: 1px solid #e0f5e9;
  background: initial;
  color: #fff;
  font-family: "Orbitron";
  font-size: clamp(16px,2vw,24px);
  //border:1px solid red;
  :focus {
    outline: none;
  }
  @media (max-width: 910px) {
    // max-width: 100%;
    width: 70vw;
    line-height: 18px;
    grid-column-start: 1;
    grid-row-start: 3;
  }
     @media (max-width: 530px) {
    width: 90vw;
  }
`;

export const DivBoxColumnsMoneySC = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 35px;
  height: 150px;
  width: 100%;
  //border solid red;
  max-width: max-content;
   @media (max-width: 910px) {
     height: 100px;
  }
  @media (max-width: 410px) {
    grid-column-gap: 32px;
  }
`;

export const DivBoxRowsRightPenalSC = styled.div`
  max-width: 621px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 295px 79px 203px;
  @media (max-width: 810px) {
    max-width: 100%;
  }
`;


export const DivBoxColumnIconTextSC = styled.div`
  width: 100%;
  display: grid;
  align-self: end;
  // grid-template-columns: 1fr 6fr;
  grid-template-rows: repeat(2, max-content);
  gap: 60px;
  grid-column-start: 2;
  grid-row-start: 2;
  @media (max-width: 910px) {
    grid-column-start: 1;
    grid-row-start: 2;
    gap: 20px;
      width: 70vw;
  }
   //border:1px solid red;
`;

export const DivBoxIconClipSC = styled.div`
  cursor: pointer;
  width: 24px;
  height: 28px;
  background-size: 100%;
  background-image: url(${Clip});
  @media (max-width: 1060px) {
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const DivTextAddAttachmentSC = styled.div`
  //width: 100%;
  display: grid;
  //align-self: flex-start;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(16px,2vw,24px);
  line-height: 30px;
`;
export const DivTwoColumnsSC = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  gap: 10px;
`;
export const ButtonGradientSC = styled.button`
  text-decoration: none;
  width: 300px;
  height: 80px;
  border: none;
  font-family: "Orbitron";
  max-width: 427px;
  max-height: 150px;
  display: grid;
  /* justify-self: center; */
  align-self: center;
  background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
  box-shadow: 0px 0px 80px #311941;
  border-radius: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(30px,5vw,35px);
  line-height: 50px;
  justify-items: center;
  align-items: center;
  color: #fff;
  // border:1px solid red;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.7;
  }
  @media (max-width: 1060px) {
    // max-width: 350px;
    max-height: 105px;
  }
  @media (max-width: 910px) {
    display: none;
  }
  /* @media (max-width: 410px) {
    max-width: 300px;
    max-height: 80px;
    font-size: 36px;

  } */
`;
export const ButtonGradientSmallSC = styled.button`
  display: none;
  @media (max-width: 910px) {
    display: grid;

    text-decoration: none;
    width:50%;
    height: 80px;
    border: none;
    font-family: "Orbitron";
    max-width: 427px;
    max-height: 150px;
    align-items: center;

    /* justify-self: center; */
    /* align-self: center; */
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    box-shadow: 0px 0px 80px #311941;
    border-radius: 20px;
    //flex: none;
    //order: 0;
    //flex-grow: 0;
    font-style: normal;
    font-weight: 400;
    font-size: clamp(32px,5vw,40px);
    line-height: 50px;
    color: #fff;
    // border:1px solid red;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
    :active {
      opacity: 0.7;
    }
    @media (max-width: 410px) {
      max-width: 300px;
      max-height: 80px;
    
    }
  }
  @media (max-width: 480px) {
    width:70%;

  }
`;
export const SpanBudgetSC = styled.div`
  width: 100%;
  height: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(14px,2vw,18px);
  line-height: 30px;
  text-align: start;
  margin-left: 10px;
  @media (max-width: 1175px) {
      
    margin-left: 5px;
    }
`; 

export const DivTextTellUS = styled.div`
  width: 100%;
  //align-self: flex-start;
  height: 100%;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  font-size: clamp(20px,2vw,24px);
  @media (max-width:910px) {
    width: 70vw;
  }
  @media (max-width:530px) {
    width: 90vw;
    font-size: 16px;
  }
`;

export const TellUsTextSC = styled(Link)`
  color:transparent;
  --g:linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
  background: 
    var(--g),
    var(--g)  bottom 2px left 0/100% 2px no-repeat; /* adjust the bottom value to control the offset */
  background-clip: text,padding-box;
  -webkit-background-clip: text,padding-box;
  width: fit-content
`;