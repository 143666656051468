import React, { useState } from "react";
import {
    DivBoxItemCarouselSC,
    DivInnerItemCarouselSC,
    DivBoxIconSC,
    ButtonSC,
    DivTwoRowsSC,
} from "../../../styles/styles.ourService";
import Glitchv2 from "../../glitchv2";

import { AiFillInfoCircle } from "react-icons/ai";

const ItemCarousel = (props) => {
    const { text, textD, borderRadius, fontSize, fontSizeIcon, href } = props;

    const [isShowDiscr, setIsShowDiscr] = useState(false);

    const onClick = () => {
        setIsShowDiscr(!isShowDiscr);
    };
    var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(
            !window["safari"] ||
                (typeof safari !== "undefined" &&
                    window["safari"].pushNotification)
        );
    return (
        <DivBoxItemCarouselSC borderRadius={borderRadius} isSafari={!isSafari}>
            {isShowDiscr ? (
                <DivInnerItemCarouselSC
                    borderRadius={borderRadius}
                    isSmallText={textD.length > 53}
                >
                    <DivBoxIconSC fontSizeIcon={fontSizeIcon}>
                        <AiFillInfoCircle size={fontSizeIcon} />
                    </DivBoxIconSC>

                    <Glitchv2>{textD}</Glitchv2>
                </DivInnerItemCarouselSC>
            ) : (
                <DivInnerItemCarouselSC
                    borderRadius={borderRadius}
                    fontSize={fontSize}
                >
                    <DivBoxIconSC fontSizeIcon={fontSizeIcon}>
                        <AiFillInfoCircle size={fontSizeIcon} />
                    </DivBoxIconSC>
                    <Glitchv2>
                        <DivTwoRowsSC>
                            {text}

                           
                        </DivTwoRowsSC>
                        {/* {text} */}{" "}
                    </Glitchv2>
                </DivInnerItemCarouselSC>
            )}
        </DivBoxItemCarouselSC>
    );
};

export default ItemCarousel;
