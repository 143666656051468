import React, { useState } from "react";
import {
    DivBoxAboutSC,
    DivBoxColumnsPagesSC,
    DivBoxTitleSC,
    DivPageSC,
    DivLineSC,
    DivBoxteamTextSC,
} from "../../styles/section3.styles";
import AboutTeamDeveloperCarousel from "./AboutTeamDeveloperCarousel";

const MainSection3 = (props) => {
    const { bottomRefAbout } = props;
    const [indexSelectedButton, getIndexButton] = useState(0);

    const listContent = [
        {
            page: (
                <>
                    <AboutTeamDeveloperCarousel
                        indexSelectedButton={indexSelectedButton}
                    ></AboutTeamDeveloperCarousel>
                </>
            ),
        },
        {
            page: (
                <>
                    <AboutTeamDeveloperCarousel
                        indexSelectedButton={indexSelectedButton}
                    ></AboutTeamDeveloperCarousel>
                </>
            ),
        },
        {
            page: (
                <>
                    <AboutTeamDeveloperCarousel
                        indexSelectedButton={indexSelectedButton}
                    ></AboutTeamDeveloperCarousel>
                </>
            ),
        },
        // {
        //   page: (
        //     <>
        //       <AboutTeamDeveloperCarousel
        //         indexSelectedButton={indexSelectedButton}
        //       ></AboutTeamDeveloperCarousel>
        //     </>
        //   ),
        // },
    ];
    const onClickTab = (index) => (e) => {
        getIndexButton(index);
    };

    return (
        <DivBoxAboutSC ref={bottomRefAbout}>
            <DivBoxTitleSC>About</DivBoxTitleSC>
            <DivBoxteamTextSC>Team</DivBoxteamTextSC>
            <div style={{ maxWidth: "1720px" }}>
                <DivBoxColumnsPagesSC>
                    <DivPageSC
                        isActive={indexSelectedButton === 0}
                        onClick={onClickTab(0)}
                    >
                        Leadership
                    </DivPageSC>
                    <DivPageSC
                        isActive={indexSelectedButton === 1}
                        onClick={onClickTab(1)}
                    >
                        Development
                    </DivPageSC>
                    <DivPageSC
                        isActive={indexSelectedButton === 2}
                        onClick={onClickTab(2)}
                    >
                        Design
                    </DivPageSC>
                </DivBoxColumnsPagesSC>
                <DivLineSC />
            </div>
            {listContent[indexSelectedButton].page}
        </DivBoxAboutSC>
    );
};

export default MainSection3;
