import styled from "styled-components/macro";
import photo from "../images/image.png";
export const DivBoxContentServiceSC = styled.div`
    height: calc(100vw / 4) !important;
    min-height: 570px !important;
    display: grid;
    color: white;
    grid-template-columns: 100%;
    grid-template-rows: clamp(35px, 8vw, 80px) auto;
    overflow: hidden;
    gap: 20px;
    @media (max-width: 1600px) {
        gap: 0px;
    }
`;
export const DivOpisanSC = styled.div`
    display: flex;

    flex-direction: column;
    gap: 5px;
`;
export const DivNameSC = styled.div`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.05em;

    color: #ffffff;
    @media (max-width: 1100px) {
        font-size: 25px;
        line-height: 35px;
    }
`;
export const ButtonShowSC = styled.button`
    padding: 10px 36px;
    gap: 10px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.05em;
    border: none;
    color: #ffffff;
    width: 243px;
    height: 78px;
    border-radius: 18px;

    @media (max-width: 1100px) {
        font-size: 20px;
        line-height: 20px;
        width: 165px;
        height: 53px;
        border-radius: 8px;
    }

    /* Голубой-розовый */
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);

    color: white;
    cursor: pointer;
    :hover {
        scale: 0.97;
        transition: 0.5s;
    }
    :active {
        scale: 0.96;
        transition: 0.5s;
    }
`;

export const DivBoxSliderSC = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    @media (min-width: 1600px) {
        height: calc(100% + 0px);
    }
    // border: 1px solid red;
`;

export const H3Sc = styled.h3`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;

    font-size: clamp(26px, 5vw, 80px);
    line-height: clamp(33px, 5vw, 65px);

    /* identical to box height */
    letter-spacing: 0.08em;
    margin: 0;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 4px 12px rgb(213 22 255 / 83%);
    // border: 1px solid red;
`;

export const DivBoxItemCarouselSC = styled.div`
    height: calc(100% - 60px);
    width: calc(100vw / 2);
    border-radius: 20px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    margin-left: 10px;
    margin-right: 10px;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        ${({ Photo }) => Photo && `url(${Photo})`};
    justify-items: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 700px) {
        width: calc(100vw / 1.05);
        height: calc(100% - 42px);
        border-radius: 10px;
        margin-bottom: 30px;
    }
    @media (min-width: 1600px) {
        height: calc(100% + 0px);
        margin-top: 20px;
    }
`;

export const DivInnerItemCarouselSC = styled.div`
    display: grid;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    background: radial-gradient(
        73.28% 231.6% at 26.72% 52.96%,
        #0e1a5a 0%,
        #0e1a5a 5.27%,
        #21004b 100%
    );
    border-radius: ${({ borderRadius }) => borderRadius - 1}px;
    justify-items: center;
    align-items: center;
    text-align: center;
    position: relative;
    /* font-family: 'Kanit'; */
    font-style: normal;
    font-weight: 400;
    font-size: ${({ fontSize }) => fontSize}px;
    padding: 5px;
    box-sizing: border-box;
`;

export const DivBoxIconSC = styled.div`
    position: absolute;
    top: ${({ fontSizeIcon }) => (fontSizeIcon === 10 ? 5 : 10)}px;
    right: ${({ fontSizeIcon }) => (fontSizeIcon === 10 ? 5 : 10)}px;
    /* height: 10px;
    width: 10px; */
    display: grid;
    color: #d516ff;
`;
export const ButtonArrowLeftSC = styled.button`
    /* position: absolute; */
    z-index: 100;
    margin-left: -120px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    cursor: pointer;
    :hover > svg {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    }
    /*     @media (max-width: 480px) {
        margin-left: -25px;
        width: 20px;
    } */
`;

export const ButtonArrowRightSC = styled.button`
    /* position: absolute; */
    padding-left: 35px;
    z-index: 100;
    width: 30px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    cursor: pointer;
    /*     @media (max-width: 480px) {
        width: 20px;
        padding-left: 10px;
    } */
`;
