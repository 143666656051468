import React, { useState } from 'react'
import {
	ButtonHeaderSC,
	DivBoxDisplaySC,
	DivContainerNavBarHomeSC,
	DivLinksScrollSC,
	DivLogoBoxSC,
	DivMaxWidthSC,
	DivTextDisplayNoneSC,
	DivTextMenuSC,
	HeaderSC,
	LinkToHomeSC,
	TextNavBarSC,
} from '../../styles/header.styles'
import { DivTextShowreelSC } from '../../styles/styled.section1'
import Showreel from '../Showreel'
import './Slidebar.css'
const Header = (props) => {
	const [isChosen, setIsChosen] = useState()
	const [openCreditCard, setOpenCreditCard] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	console.log(isOpen)
	const OpenModal = () => {
		setIsOpen(!isOpen)
		console.log(isOpen)
	}
	const {
		onClickButtonHome,
		onClickButtonAbout,
		onClickButtonServices,
		onClickButtonProject,
		onClickButtonContacts,
		onClickButtonCalendar,
	} = props
	const firstButton = (props) => {
		setIsChosen(1)
		const onClickButtonHome = props
	}
	const secondButton = () => {
		setIsChosen(2)
	}
	const thirddButton = () => {
		setIsChosen(3)
	}
	const fourddButton = () => {
		setIsChosen(4)
	}
	const frirddButton = () => {
		setIsChosen(5)
	}
	const sixthButton = () => {
		setIsChosen(6)
	}
	// console.log(isChosen);

	return (
		<HeaderSC>
			<>
				<DivMaxWidthSC>
					<DivContainerNavBarHomeSC>
						<LinkToHomeSC to='/'>
							<DivLogoBoxSC>
								{/* <LogoNavBarSC /> */}
								<TextNavBarSC>Cyberzen Labs</TextNavBarSC>
							</DivLogoBoxSC>
						</LinkToHomeSC>

						<DivLinksScrollSC>
							<DivTextMenuSC
								onClick={onClickButtonHome}
								isChosen={isChosen === 1}
							>
								HOME
							</DivTextMenuSC>
							<DivTextMenuSC
								onClick={onClickButtonAbout}
								isChosen={isChosen === 2}
							>
								ABOUT
							</DivTextMenuSC>
							<DivTextMenuSC
								onClick={onClickButtonServices}
								isChosen={isChosen === 3}
							>
								SERVICES
							</DivTextMenuSC>
							<DivTextMenuSC
								onClick={onClickButtonProject}
								isChosen={isChosen === 4}
							>
								PROJECTS
							</DivTextMenuSC>
							<DivTextMenuSC
								onClick={onClickButtonContacts}
								isChosen={isChosen === 5}
							>
								CONTACTS
							</DivTextMenuSC>
							<ButtonHeaderSC
								onClick={onClickButtonCalendar}
								isChosen={isChosen === 6}
							>
								{' '}
								WRITE TO US
							</ButtonHeaderSC>
						</DivLinksScrollSC>
						<DivBoxDisplaySC>
							<ButtonHeaderSC
								onClick={onClickButtonCalendar}
								isChosen={isChosen === 6}
							>
								{' '}
								WRITE TO US
							</ButtonHeaderSC>
						</DivBoxDisplaySC>
						<div className='hamburger-menu'>
							<input id='menu__toggle' type='checkbox' />
							<label className='menu__btn' htmlFor='menu__toggle'>
								<span></span>
							</label>
							<ul className='menu__box'>
								<li>
									<input id='menu__toggle' type='checkbox' />
									<label
										className='menu__item'
										htmlFor='menu__toggle'
										onClick={onClickButtonHome}
									>
										HOME
									</label>
								</li>
								<li>
									<input id='menu__toggle' type='checkbox' />
									<label
										className='menu__item'
										htmlFor='menu__toggle'
										onClick={onClickButtonAbout}
									>
										ABOUT
									</label>
								</li>
								<li>
									<input id='menu__toggle' type='checkbox' />
									<label
										className='menu__item'
										htmlFor='menu__toggle'
										onClick={onClickButtonServices}
									>
										SERVICES
									</label>
								</li>
								<li>
									<input id='menu__toggle' type='checkbox' />
									<label
										className='menu__item'
										htmlFor='menu__toggle'
										onClick={onClickButtonProject}
									>
										PROJECTS
									</label>
								</li>
								<li>
									<input id='menu__toggle' type='checkbox' />
									<label
										className='menu__item'
										htmlFor='menu__toggle'
										onClick={onClickButtonContacts}
									>
										CONTACTS
									</label>
								</li>
								<li>
									<DivTextDisplayNoneSC>
										<DivTextShowreelSC
											onClick={OpenModal}
											isOpen={isOpen}
											setIsOpen={setIsOpen}
										>
											SHOWREEL
										</DivTextShowreelSC>
										<Showreel isOpen={isOpen} setIsOpen={setIsOpen} />
									</DivTextDisplayNoneSC>
								</li>
							</ul>
						</div>
					</DivContainerNavBarHomeSC>
				</DivMaxWidthSC>
			</>
			<div class='progress-container'>
				<div class='progress-bar' id='myBar'></div>
			</div>
		</HeaderSC>
	)
}

export default Header
