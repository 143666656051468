import React, { useState } from "react";
import {
  DivContainerBoxSC,
  DivFormBoxSC,
  DivFormDataBoxSC,
  DivInputBoxSC,
  H1TitleBoxSC,
  InputSC,
  LabelInputSC,
  LinkNavBarBoxSC,
  LogoNavBarSC,
  TextareaSC,
  TextNavBarSC,
  SpanBudgetSC,
  DivBoxColumnsMoneySC,
  DivBoxTextSC,
  TeaxAreaSC,
  LabelTeaxtAreaSC,
  ButtonsSendCS,
} from "../../styles/styled.brief";
import InputMask from "react-input-mask";
const Marketing = () => {
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [focusedPhone, setFocusedPhone] = useState("");
  const [focusedBudget, setFocusedBudget] = useState("");

  const handlePhoneInput = ({ target }) => {
    setFocusedPhone(target.id);
  };

  const handleBudgetInput = ({ target }) => {
    setFocusedBudget(target.id);
  };
  const [terms, setTerms] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [terms3, setTerms3] = useState(false);

  const Terms = (e) => {

    setTerms(e.target.value);
  };

  const Terms2 = () => {
    setTerms2(!terms2);
  };
  const Terms3 = () => {
    setTerms3(!terms3);
  };



  return (
    <>
      <DivInputBoxSC>
        <LabelInputSC for="name">What is the overall goal of your project?</LabelInputSC>
        <InputSC name="What is the overall goal of your project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Who is your target audience?</LabelInputSC>
        <InputSC name="Who is your target audience?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">What is the scope of your project?</LabelInputSC>
        <InputSC name="What is the scope of your project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any specific feature requests or functionalities in mind?</LabelInputSC>
        <InputSC name="Do you have any specific feature requests or functionalities in mind?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have a timeline in mind for the project?</LabelInputSC>
        <InputSC name="Do you have a timeline in mind for the project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have a budget allocated for the project?</LabelInputSC>
        <InputSC name="Do you have a budget allocated for the project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any existing branding guidelines that we should be aware of?</LabelInputSC>
        <InputSC name="Do you have any existing branding guidelines that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any specific technical requirements or preferences?</LabelInputSC>
        <InputSC name="Do you have any specific technical requirements or preferences?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any existing content or assets that we can use for the project?</LabelInputSC>
        <InputSC name="Do you have any existing content or assets that we can use for the project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Who will be responsible for providing content for the project?</LabelInputSC>
        <InputSC name="Who will be responsible for providing content for the project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any existing social media accounts or online presence that we should be aware of?</LabelInputSC>
        <InputSC name="Do you have any existing social media accounts or online presence that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any specific competitors that we should consider when developing the project?</LabelInputSC>
        <InputSC name="Do you have any specific competitors that we should consider when developing the project?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any existing analytics or tracking systems in place?</LabelInputSC>
        <InputSC name="Do you have any existing analytics or tracking systems in place?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any specific SEO or keyword targets that we should be aware of?</LabelInputSC>
        <InputSC name="Do you have any specific SEO or keyword targets that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any specific legal or compliance considerations that we should be aware of?</LabelInputSC>
        <InputSC name=">Do you have any specific legal or compliance considerations that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any existing partnerships or collaborations that we should be aware of?</LabelInputSC>
        <InputSC name="Do you have any existing partnerships or collaborations that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Who will be responsible for managing and updating the project once it is launched?</LabelInputSC>
        <InputSC name="Who will be responsible for managing and updating the project once it is launched?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any specific maintenance or support needs that we should be aware of?</LabelInputSC>
        <InputSC name="Do you have any specific maintenance or support needs that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Are there any potential challenges or constraints that we should be aware of?</LabelInputSC>
        <InputSC name="Are there any potential challenges or constraints that we should be aware of?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <LabelInputSC for="name">Do you have any additional questions or concerns that you would like to discuss during the onboarding process?</LabelInputSC>
        <InputSC name="Do you have any additional questions or concerns that you would like to discuss during the onboarding process?" type={"text"} id="name" />
      </DivInputBoxSC>
      <DivInputBoxSC><ButtonsSendCS type="submit">Send a brief</ButtonsSendCS></DivInputBoxSC>
    </>
  );
};

export default Marketing;
