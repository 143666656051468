import React, { useState } from 'react'

import {
    DivBoxItemCarouselSC,
    ButtonShowSC,
    DivOpisanSC,
    DivNameSC,
    DivInnerItemCarouselSC,
    DivPhotoSC,
    ButtonSC
   
} from '../../../styles/styled-section5'

import { AiFillInfoCircle } from 'react-icons/ai'
import Glitchv2 from '../../glitchv2'

const ItemCarousel = (props) => {
 
    const {
        text,
        Photo,
        href,
        borderRadius
    } = props
   

    return (
        <Glitchv2>
        <DivBoxItemCarouselSC
        borderRadius={borderRadius}
        >

        <DivInnerItemCarouselSC
                borderRadius={borderRadius}

        >
            <DivPhotoSC 
            borderRadius={borderRadius}
            Photo={Photo}/>
            <DivOpisanSC>
            <Glitchv2> <DivNameSC>{text}</DivNameSC>

                
                <a href={href} target="_blank"><ButtonSC > View</ButtonSC></a>

                </Glitchv2>

            </DivOpisanSC>
           


        </DivInnerItemCarouselSC>
        </DivBoxItemCarouselSC>
        </Glitchv2>
    )
}

export default ItemCarousel