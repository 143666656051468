import { Canvas } from '@react-three/fiber'
import React, { Suspense } from 'react'
import Model from '../../3d-obj/Buddha'
import {
	AdiskordSC,
	BuddhaBackHomeSC,
	ButtonShowreelSC,
	DivBoxBackSC,
	DivBoxDisplayGridText2SC,
	DivBoxDisplayGridTextSC,
	DivBuddaPngSC,
	DivBuddhaBox2SC,
	DivBuddhaBoxSC,
	DivDisplayGridSC,
	DivFirstRowBoxSC,
	DivNowSC,
	DivRowBoxSC,
	DivSecondRowBoxSC,
	DivSection1BoxSC,
	DivShadowBoxSC,
	DivText2SC,
	DivTextRowBoxSC,
	DivTextSC,
	DivTextShowreelSC,
	SloganBoxSC,
	TitleHomeBox2SC,
	TitleHomeBoxSC,
} from '../../styles/styled.section1'

import Typewriter from 'typewriter-effect'

import { useState } from 'react'
import { ButtonHeaderSC } from '../../styles/header.styles'
import { ButtonSC } from '../../styles/styles.ourService'
import GlitchV1 from '../glitchv1'
import Showreel from '../Showreel'

const MainSection1 = (props) => {
	function iOS() {
		return (
			[
				'iPad Simulator',
				'iPhone Simulator',
				'iPod Simulator',
				'iPad',
				'iPhone',
				'iPod',
			].includes(Navigator.platform) ||
			// iPad on iOS 13 detection
			(Navigator.userAgent.includes('Mac') && 'ontouchend' in document)
		)
	}
	const isIOS = typeof navigator.standalone === 'boolean'

	const [isOpen, setIsOpen] = useState(false)
	console.log(isOpen)
	const OpenModal = () => {
		setIsOpen(!isOpen)
		console.log(isOpen)
	}
	const { bottomRefHome, onClickButtonCalendar } = props
	const [isChosen, setIsChosen] = useState()
	var isSafari =
		/constructor/i.test(window.HTMLElement) ||
		(function (p) {
			return p.toString() === '[object SafariRemoteNotification]'
		})(
			!window['safari'] ||
				(typeof safari !== 'undefined' && window['safari'].pushNotification)
		)
	console.log('check', iOS, isSafari)

	const sixthButton = () => {
		setIsChosen(6)
	}
	console.log(isChosen)

	return (
		<DivSection1BoxSC ref={bottomRefHome}>
			<DivRowBoxSC>
				<TitleHomeBox2SC>
					<GlitchV1
						color={'#BF09FF'}
						fontFamily={'Ron-Medium'}
						fontSize={'clamp(26px, 8vw, 160px)'}
					>
						CYBERZEN LABS
					</GlitchV1>
					CYBERZEN LABS
					<DivShadowBoxSC isSafari={!isSafari} />
				</TitleHomeBox2SC>
				<DivFirstRowBoxSC>
					<DivTextRowBoxSC>
						<TitleHomeBoxSC>
							<GlitchV1 color={'#BF09FF'}>CYBERZEN LABS</GlitchV1>
							CYBERZEN LABS
							<DivShadowBoxSC isSafari={!isSafari} />
						</TitleHomeBoxSC>
						<SloganBoxSC>
							The future is <DivNowSC>now</DivNowSC>
						</SloganBoxSC>
						<DivText2SC>
							<Typewriter
								options={{
									delay: 20,
								}}
								onInit={(typewriter) => {
									typewriter
										.typeString(
											'International superstar team creating enterprise grade Web2 and Web3 software, and other web services '
										)

										.pauseFor(2500)
										.stop()

										.start()
								}}
							/>
						</DivText2SC>
						<DivBoxDisplayGridTextSC>
							{/* <AdiskordSC
                                href="https://discord.com/channels/968070664214970408/1053748724989444198"
                                target="_blank"
                            >
                                <ButtonSC>
                                    <DivTextShowreelSC>
                                        Go to Discord
                                    </DivTextShowreelSC>
                                </ButtonSC>
                            </AdiskordSC> */}
							<AdiskordSC
								onClick={OpenModal}
								isOpen={isOpen}
								setIsOpen={setIsOpen}
							>
								<ButtonSC>
									<DivTextShowreelSC>SHOWREEL</DivTextShowreelSC>
									<Showreel isOpen={isOpen} setIsOpen={setIsOpen} />
								</ButtonSC>
							</AdiskordSC>
						</DivBoxDisplayGridTextSC>
					</DivTextRowBoxSC>

					{isIOS === true || isSafari ? (
						<DivBuddhaBox2SC>
							<DivBuddaPngSC></DivBuddaPngSC>
						</DivBuddhaBox2SC>
					) : (
						<DivBuddhaBoxSC>
							<BuddhaBackHomeSC />
							<Canvas camera={{ position: [0, 1.5, 20], zoom: 1 }}>
								<hemisphereLight intensity={0.1} />
								<Suspense fallback={null}>
									<Model scale={0.6} />
								</Suspense>
							</Canvas>
						</DivBuddhaBoxSC>
					)}
				</DivFirstRowBoxSC>
				<DivSecondRowBoxSC>
					<DivTextSC>
						International superstar team creating enterprise grade Web2 and Web3
						software, and other web services
					</DivTextSC>
				</DivSecondRowBoxSC>

				<DivBoxDisplayGridText2SC>
					<DivDisplayGridSC>
						<ButtonHeaderSC
							onClick={onClickButtonCalendar}
							style={{ marginTop: '20px', width: '100%' }}
							isChosen={isChosen === 6}
						>
							{' '}
							WRITE TO US
						</ButtonHeaderSC>
					</DivDisplayGridSC>

					{/* <AdiskordSC
						href='https://discord.com/channels/968070664214970408/1053748724989444198'
						target='_blank'
					>
						<ButtonHeaderSC style={{ width: '100%' }}>
							Go to Discord
						</ButtonHeaderSC>
					</AdiskordSC> */}

					<ButtonShowreelSC
                        onClick={OpenModal}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
                    >
                        <DivBoxBackSC>
							<DivTextShowreelSC>SHOWREEL</DivTextShowreelSC> 
							<Showreel isOpen={isOpen} setIsOpen={setIsOpen} />
						</DivBoxBackSC>
					</ButtonShowreelSC>
				</DivBoxDisplayGridText2SC>
			</DivRowBoxSC>
		</DivSection1BoxSC>
	)
}

export default MainSection1
