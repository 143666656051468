import React, { useContext, useEffect, useState } from "react";

import Carousel from "react-multi-carousel";
import {
  DivContainerAboutDevSC,
  DivDecriptionTextSC,
  GridContainerItemsSC,
  DivCustomTextSC,
  GridRowsIcons,
  ImgIconSC,
  GridColumnsIcons,
  GridColumnsSecondIcons,
  GridIconsSC,
  CarouselHidden,
  DivBlure,
  DivLineAboutDevSC,
  DivContainerSC,
  DivWrapSC,

} from "../../styles/styled-section4";
import AboutDevCarousel from "./AboutDevCarousel";




const MainSection4 = (props) => {
  const {bottomRefHome} = props;
  return (
    <>
     
        <DivContainerSC>
          <DivContainerAboutDevSC>
           

            <GridRowsIcons>
              <DivDecriptionTextSC>
                <DivCustomTextSC>What we used:</DivCustomTextSC>
              </DivDecriptionTextSC>


              <AboutDevCarousel></AboutDevCarousel>

            </GridRowsIcons>

          </DivContainerAboutDevSC>
        </DivContainerSC>
    </>
  );
}

export default MainSection4