import React, { useState, useEffect } from "react";
import {
  ButtonArrowLeftSC,
  DivBoxContentServiceSC,
  DivBoxSliderSC,
  H3Sc,
  ButtonArrowRightSC,
  TitleBoxSC,
  MarginBottomSC,
  ButtonArrowRight2SC,
  ButtonArrowLeft2SC,
} from "../../styles/styles.ourService";
import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ItemCarousel from "./components/itemCarousel";
import "../../index.css";
import AutoplayCarousel from "./components/AutoplayCarousel";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import GlitchV1 from "../glitchv1";

const MainSection2 = (props) => {
  const { bottomRefServices } = props;
  const [width, setWidth] = useState(0);
  const [isShowCarousel, setIsShowCarousel] = useState(false);
  const isIOS = typeof navigator.standalone === 'boolean';
  useEffect(() => {
    setWidth(window.innerWidth);
    window.onresize = onResize;
    setTimeout(() => {
      setIsShowCarousel(true);
    }, 300);
  }, []);

  const onResize = (e) => {
    setWidth(e.target.innerWidth);

    if (e.target.innerWidth > 961) {
      setIsShowCarousel(false);
      setTimeout(() => {
        setIsShowCarousel(true);
      }, 300);
    }
  };

  return (
    <>
      {/* <MarginBottomSC>
            <TitleBoxSC>
                <H3Sc>
                    Our partners and clients
                </H3Sc>
            </TitleBoxSC>
        <AutoplayCarousel/>
            </MarginBottomSC> */}
      <DivBoxContentServiceSC ref={bottomRefServices}>
        <TitleBoxSC>
          <H3Sc>
            {/*   <GlitchV1
                        color={"#BF09FF"}
                        fontFamily={"Orbitron"}
                        fontSize={"clamp(26px,5vw,80px)"}
                        literSpacing={"0.085em"}
                    >
                        Our services
                    </GlitchV1> */}
            Our services
          </H3Sc>
        </TitleBoxSC>

        {isShowCarousel ? (
          <DivBoxSliderSC>
            {width > 961 ? (
              <Carousel
                plugins={[
                  "centered",
                  "infinite",
                  // 'arrows',
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 2,
                    },
                  },
                  {
                    resolve: arrowsPlugin,
                    options: {
                      // numberOfSlides: 2,
                      arrowLeft: (
                        <ButtonArrowLeftSC>
                          <IoIosArrowBack size={30} />
                        </ButtonArrowLeftSC>
                      ),
                      arrowLeftDisabled: (
                        <ButtonArrowLeftSC>
                          <IoIosArrowBack size={30} />
                        </ButtonArrowLeftSC>
                      ),
                      arrowRight: (
                        <ButtonArrowRightSC>
                          <IoIosArrowForward size={30} />
                        </ButtonArrowRightSC>
                      ),
                      arrowRightDisabled: (
                        <ButtonArrowRightSC>
                          <IoIosArrowForward size={30} />
                        </ButtonArrowRightSC>
                      ),
                      addArrowClickHandler: true,
                    },
                  },
                ]}
                draggable={false}
              >
                <ItemCarousel
                  text={"Software development"}
                  textD={``}
                  borderRadius={26}
                  fontSize={64}
                  fontSizeIcon={32}
                  
                />
                  <ItemCarousel
                    text={"UX/UI Design"}
                    textD={
                      "Creating a convenient attractive and functional interface"
                    }
                    borderRadius={26}
                    fontSize={64}
                    fontSizeIcon={32}
                   
                  />
                {/* <ItemCarousel
                  text={"Web/Mobile development"}
                  textD={
                    "Development of web applications and their mobile versions of any complexity"
                  }
                  borderRadius={26}
                  fontSize={64}
                  fontSizeIcon={32}
                  href={
                    "https://www.canva.com/design/DAFUY0sVj0U/8PyueuE8RtMkfw6ipUKmqw/view?utm_content=DAFUY0sVj0U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"
                  }
                /> */}
                <ItemCarousel
                  text={"Blockchain / NFT development"}
                  textD={``}
                  borderRadius={26}
                  fontSize={64}
                  fontSizeIcon={32}
                 
                />
                <ItemCarousel
                  text={"Automation & AI chat bots"}
                  textD={``}
                  borderRadius={26}
                  fontSize={64}
                  fontSizeIcon={32}
                 
                />
                <ItemCarousel
                  text={"Marketing"}
                  textD={``}
                  borderRadius={26}
                  fontSize={64}
                  fontSizeIcon={32}
                 
                />
                {/* <ItemCarousel
								text={'Cryptocurrency trading'}
								textD={'Web/Mobile development'}
								borderRadius={26}
								fontSize={64}
								fontSizeIcon={32}
							/> */}
                {/* <ItemCarousel
						text={'Web/Mobile development'}
					/>
					<ItemCarousel
						text={'Web/Mobile development'}
					/>
					<ItemCarousel
						text={'Web/Mobile development'}
					/> */}




              </Carousel>
            ) : (
              <Carousel
              
                plugins={[
                  "centered",
                  "infinite",
                  // 'arrows',
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: 2,
                    },
                  },
                  {
                    resolve: arrowsPlugin,
                    options: {
                      // numberOfSlides: 2,
                      arrowLeft: (<>
                        {isIOS?( <ButtonArrowLeft2SC>
                          <IoIosArrowBack size={20} />
                        </ButtonArrowLeft2SC>):(<ButtonArrowLeftSC>
                          <IoIosArrowBack size={20} />
                        </ButtonArrowLeftSC>)}
                        </>
                      ),
                      arrowLeftDisabled: (<>
                        {isIOS?( <ButtonArrowLeft2SC>
                          <IoIosArrowBack size={20} />
                        </ButtonArrowLeft2SC>):(<ButtonArrowLeftSC>
                          <IoIosArrowBack size={20} />
                        </ButtonArrowLeftSC>)}
                        </>
                      ),
                      arrowRight: (
                        <>
                        {isIOS?( <ButtonArrowRight2SC>
                          <IoIosArrowForward size={20} />
                        </ButtonArrowRight2SC>):(<ButtonArrowRightSC>
                          <IoIosArrowForward size={20} />
                        </ButtonArrowRightSC>)}
                        </>
                      
                      ),
                      arrowRightDisabled: (
                      
                        <>
                        {isIOS?( <ButtonArrowRight2SC>
                          <IoIosArrowForward size={20} />
                        </ButtonArrowRight2SC>):(<ButtonArrowRightSC>
                          <IoIosArrowForward size={20} />
                        </ButtonArrowRightSC>)}
                        </>
                      ),
                      addArrowClickHandler: true,
                    },
                  },
                ]}
              >
              
              <ItemCarousel
                  text={"Software development"}
                  textD={``}
                  borderRadius={11}
                  fontSize={16}
                  fontSizeIcon={10}
                 
                />
                  <ItemCarousel
                    text={"UX/UI Design"}
                    textD={
                      "Creating a convenient attractive and functional interface"
                    }
                    borderRadius={11}
                    fontSize={16}
                    fontSizeIcon={10}
                    
                  />
                {/* <ItemCarousel
                  text={"Web/Mobile development"}
                  textD={
                    "Development of web applications and their mobile versions of any complexity"
                  }
                  borderRadius={26}
                  fontSize={64}
                  fontSizeIcon={32}
                  href={
                    "https://www.canva.com/design/DAFUY0sVj0U/8PyueuE8RtMkfw6ipUKmqw/view?utm_content=DAFUY0sVj0U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"
                  }
                /> */}
                <ItemCarousel
                  text={"Blockchain / NFT development"}
                  textD={``}
                  borderRadius={11}
                  fontSize={16}
                  fontSizeIcon={10}
                  
                />
                <ItemCarousel
                  text={"Automation & AI chat bots"}
                  textD={``}
                  borderRadius={11}
                  fontSize={16}
                  fontSizeIcon={10}
                 
                />
                <ItemCarousel
                  text={"Marketing"}
                  textD={``}
                  borderRadius={11}
                  fontSize={16}
                  fontSizeIcon={10}
                
                />
                {/* <ItemCarousel
					text={'Web/Mobile development'}
				/>
				<ItemCarousel
					text={'Web/Mobile development'}
				/>
				<ItemCarousel
					text={'Web/Mobile development'}
				/> */}
              </Carousel>
            )}
          </DivBoxSliderSC>
        ) : null}
      </DivBoxContentServiceSC>
    </>
  );
};

export default MainSection2;