

import styled from "styled-components/macro";

export const DivWrapSC = styled.div`
   //width: 100vw;
  display: grid;
  min-height: calc(100vh - 320px);
    //border:1px solid red;
  @media (max-width: 480px) {
    min-height: calc(100vh - 260px);
  }
  //padding-top: 160px;
`;

// export const DivContainerSC = styled.div`
//   margin: 0 auto;
//   max-width: var(--max-width);
//   padding-bottom: 25px;
//   display: grid;
//   justify-items: center;
//   width: 90vw;
//  //border: 1px solid red;
// `;

export const DivContainerSC = styled.div`
    //margin: 0 auto;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    //width: 99vw;
    // border: 1px solid white;
`;

