import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const DivBoxItemCarouselSC = styled.div`
    /* max-width: 575px; */
    /* width: 574px;
    height: 464px; */
    width: 463px;
    height: 374px;
    display: grid;
    justify-content: center;
    align-content: center;
    /* box-shadow: 0px 0px 35px 0px #7167fb52; */
    border-radius: ${({ borderRadius }) => borderRadius}px;
    background: rgb(9, 187, 249);
    background: linear-gradient(
        90deg,
        rgba(9, 187, 249, 1) 0%,
        rgba(216, 21, 255, 1) 100%
    );
    margin: 0 !important;
    @media (max-width: 1600px) {
        width: 700px;
    }
    @media (max-width: 1800px) {
        width: 703px;
        height: 568px;
    }
    @media (max-width: 1500px) {
        width: 502px;
        height: 402px;
    }
    @media (max-width: 1100px) {
        width: 402px;
        height: 322px;
    }
    @media (max-width: 900px) {
        width: 302px;
        height: 252px;
    }
    @media (max-width: 900px) {
        width: 702px;
        height: 567px;
    }
    @media (max-width: 768px) {
        width: 502px;
        height: 402px;
    }
    @media (max-width: 530px) {
        width: 302px;
        height: 252px;
    }
`;

export const DivInnerItemCarouselSC = styled.div`
    display: grid;
    /* width: 570px;
height: 460px; */
    width: 461px;
    height: 372px;
    background: radial-gradient(
        73.28% 231.6% at 26.72% 52.96%,
        #0e1a5a 0%,
        #0e1a5a 5.27%,
        #21004b 100%
    );
    border-radius: ${({ borderRadius }) => borderRadius - 1}px;
    justify-items: center;
    align-items: center;
    text-align: center;
    position: relative;
    /* font-family: 'Kanit'; */
    font-style: normal;
    font-weight: 400;
    font-size: ${({ isSmallText }) => (isSmallText ? 8 : 16)}px;
    box-sizing: border-box;
    @media (min-width: 961px) {
        font-size: ${({ isSmallText }) => (isSmallText ? 20 : 32)}px;
    }
    @media (min-width: 1420px) {
        font-size: ${({ isSmallText }) => (isSmallText ? 32 : 64)}px;
    }

    @media (max-width: 1800px) {
        width: 700px;
        height: 565px;
    }

    @media (max-width: 1500px) {
        width: 500px;
        height: 400px;
    }
    @media (max-width: 1100px) {
        width: 400px;
        height: 320px;
    }
    /* @media (max-width: 900px) {
    width: 300px;
height: 250px;
} */
    @media (max-width: 900px) {
        width: 700px;
        height: 565px;
    }
    @media (max-width: 768px) {
        width: 500px;
        height: 400px;
    }
    @media (max-width: 530px) {
        width: 300px;
        height: 250px;
    }
    padding: 20px;
`;

export const ButtonShowSC = styled.button`
    padding: 10px 36px;
    gap: 10px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.05em;
    border: none;
    color: #ffffff;
    width: 243px;
    height: 78px;
    border-radius: 18px;

    @media (max-width: 1100px) {
        font-size: 20px;
        line-height: 20px;
        width: 165px;
        height: 53px;
        border-radius: 8px;
    }

    /* Голубой-розовый */
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);

    color: white;
    cursor: pointer;
    :hover {
        scale: 0.97;
        transition: 0.5s;
    }
    :active {
        scale: 0.96;
        transition: 0.5s;
    }
`;

export const DivOpisanSC = styled.div`
    display: flex;

    flex-direction: column;
    gap: 5px;
    
`;
export const DivNameSC = styled.div`
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.05em;
    color: #ffffff;
    @media (max-width: 1100px) {
        font-size: 25px;
        line-height: 35px;
    }
    @media (max-width: 900px) {
        font-size: 36px;
    }
`;

export const DivPhotoSC = styled.div`
    display: grid;
    background-image: ${({ Photo }) => Photo && `url(${Photo})`};
    border-radius: 15px;

    width: 100%;
    aspect-ratio: 518/251;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const ButtonSC = styled.button`
    padding: 10px 36px;
    font-family: "Orbitron";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    letter-spacing: 0.05em;
    border: none;
    color: #ffffff;
    border-radius: 18px;
    background: #b034fd;
 z-index: 100;
    cursor: pointer;
    @media (max-width: 1600px) {
        padding: 10px 24px;
    }
    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 20px;
        border-radius: 10px;
    }
    @media (max-width: 900px) {
        font-size: 20px;
        line-height: 40px;
        margin-top: 5px;
    }
    @media (max-width: 768px) {
        line-height: 30px;
        border-radius: 10px;
    }
    @media (max-width: 5308px) {
        line-height: 20px;
    }
    color: white;
    :hover {
        scale: 0.97;
        transition: 0.5s;
    }
    :active {
        scale: 0.96;
        transition: 0.5s;
    }
`;

export const DivCustomArrowRight = styled(IoIosArrowForward)`
    width: 30px;
    height: 30px;
    position: absolute;

    left: 52%;
    top: 90%;
    z-index: 100;
    cursor: pointer;
    @media (max-width: 480px) {
        height: 20px;
        width: 20px;
    }
`;

export const DivCustomArrowLeft = styled(IoIosArrowBack)`
    width: 30px;
    height: 30px;
    position: absolute;
    right: 52%;
    top: 90%;
    z-index: 100;
    cursor: pointer;
    @media (max-width: 480px) {
        height: 20px;
        width: 20px;
    }
`;

export const DivBoxSliderSC = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;

    // border: 1px solid red;
`;

export const H3Sc = styled.h3`
    font-family: "Ron-Medium";
    font-style: normal;
    font-weight: 700;
    height: max-content;
    font-size: clamp(26px, 5vw, 80px);

    /* line-height: clamp(33px, 5vw, 65px); */

    /* identical to box height */
    letter-spacing: 0.08em;
    margin: 0;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width:max-content;
    justify-self: center;
    // border: 1px solid red;
    text-shadow: 0px 4px 25px rgba(213, 22, 255, 0.5);

`;

export const DivBoxContentServiceSC = styled.div`
    display: grid;
    color: white;
    grid-row-gap: 30px;
`;
