import "./App.css";
import MainSection1 from "./components/section1";
import MainSection2 from "./components/section2";
import MainSection3 from "./components/section3";
import MainSection4 from "./components/section4";
import MainSection5 from "./components/section5";
import MainSection6 from "./components/section6";
import { useEffect, useRef } from "react";
import Header from "./components/header";
import "./fonts/RON.woff";
import Footer from "./components/footer";
import LazyShowLeft from "./LazyShowLeft";
import LazyShowRight from "./LazyShowRight";

function App() {
    const bottomRefContacts = useRef(null);
    const bottomRefProjects = useRef(null);
    const bottomRefServices = useRef(null);
    const bottomRefAbout = useRef(null);
    const bottomRefHome = useRef(null);
    const calendarRef = useRef(null);

    useEffect(() => {
        window.onscroll = onScroll;
    }, []);

    const onScroll = (e) => {
        var winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        var height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("myBar").style.width = scrolled + "%";
    };

    const onClickScrollContacts = () => {
        bottomRefContacts.current?.scrollIntoView({ behavior: "smooth" });
    };
    const onClickScrollServises = () => {
        bottomRefServices.current?.scrollIntoView({ behavior: "smooth" });
    };
    const onClickScrollProjects = () => {
        bottomRefProjects.current?.scrollIntoView({ behavior: "smooth" });
    };
    const onClickScrollAbout = () => {
        bottomRefAbout.current?.scrollIntoView({ behavior: "smooth" });
    };
    const onClickScrollHome = () => {
        bottomRefHome.current?.scrollIntoView({ behavior: "smooth" });
    };
    const onClickButtonCalendar = () => {
        calendarRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
        })(
            !window["safari"] ||
                (typeof safari !== "undefined" &&
                    window["safari"].pushNotification)
        );
    return (
        <div className="App-box">
            <div className="App">
                <Header
                    onClickButtonProject={onClickScrollProjects}
                    onClickButtonHome={onClickScrollHome}
                    onClickButtonAbout={onClickScrollAbout}
                    onClickButtonServices={onClickScrollServises}
                    onClickButtonContacts={onClickScrollContacts}
                    onClickButtonCalendar={onClickButtonCalendar}
                />
                {isSafari ? (
                    <>
                        <MainSection1
                            bottomRefHome={bottomRefHome}
                            onClickButtonCalendar={onClickButtonCalendar}
                        />
                        <MainSection2 bottomRefServices={bottomRefServices} />
                        <MainSection3 bottomRefAbout={bottomRefAbout} />
                        <MainSection4 />
                        <MainSection5 bottomRefProjects={bottomRefProjects} />
                        <MainSection6
                            bottomRefContacts={bottomRefContacts}
                            calendarRef={calendarRef}
                        />
                    </>
                ) : (
                    <>
                        <LazyShowLeft>
                            <MainSection1
                                bottomRefHome={bottomRefHome}
                                onClickButtonCalendar={onClickButtonCalendar}
                            />
                        </LazyShowLeft>
                        <LazyShowRight>
                            <MainSection2
                                bottomRefServices={bottomRefServices}
                            />
                        </LazyShowRight>
                        <MainSection3 bottomRefAbout={bottomRefAbout} />
                        <LazyShowRight>
                            <MainSection4 />
                        </LazyShowRight>
                        <LazyShowLeft>
                            <MainSection5
                                bottomRefProjects={bottomRefProjects}
                            />
                        </LazyShowLeft>
                        <LazyShowRight>
                            <MainSection6
                                bottomRefContacts={bottomRefContacts}
                                calendarRef={calendarRef}
                            />
                        </LazyShowRight>
                    </>
                )}
                <Footer />
            </div>
        </div>
    );
}

export default App;
