import React, {useState} from 'react'
import './styles.glitchv2.css'

const Glitchv2 = (props) => {
    const [isEnter, setIsEnter] = useState(false)

    const onEnter = (e) => {
        setIsEnter(true)
    }
    const onLeave = (e) => {
        setIsEnter(false)
    }
    return (
        <div
         onMouseEnter={onEnter}
         onMouseLeave={onLeave}
         id="appglitch">
            <div id="wrapper">
                {
                    isEnter ? <span className="glitchbefore">
                    {props.children}
                    </span> : null
                }
                
                <span className="glitch" data-text={props.children}>{props.children}</span>
                {
                    isEnter ? <span className="glitchafter">
                    {props.children}
                    </span> : null
                }
                
                {/* <span class="sub">EFFECT</span> */}
            </div>
        </div>
    )
}



export default Glitchv2