import React, { useState } from "react";
import {
  DivContainerBoxSC,
  DivFormBoxSC,
  DivFormDataBoxSC,
  DivInputBoxSC,
  H1TitleBoxSC,
  InputSC,
  LabelInputSC,
  LinkNavBarBoxSC,
  LogoNavBarSC,
  TextareaSC,
  TextNavBarSC,
  SpanBudgetSC,
  DivBoxColumnsMoneySC,
  DivBoxTextSC,
  TeaxArea2SC,
  LabelTeaxtAreaSC,
  ButtonsSendCS,
 
} from "../../styles/styled.brief";
import InputMask from "react-input-mask";
const BlockchainNFTDevelopment = () => {
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [focusedPhone, setFocusedPhone] = useState("");
  const [focusedBudget, setFocusedBudget] = useState("");

  const handlePhoneInput = ({ target }) => {
    setFocusedPhone(target.id);
  };

  const handleBudgetInput = ({ target }) => {
    setFocusedBudget(target.id);
  };
  const [terms, setTerms] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [terms3, setTerms3] = useState(false);
  const [website, setwebsite] = useState(false);
  const [mobileApp, setMobileApp] = useState(false);

  const Terms = (e) => {
      
    setTerms(e.target.value);
};
  const Terms2 = () => {
    setTerms2(!terms2);
  };
  const Terms3 = () => {
    setTerms3(!terms3);
  };
  const Website = () => {
    setwebsite(!website);
  };
  const MobileApp = () => {
    setMobileApp(!mobileApp);
  };
  console.log(website);

  return (
    <>
     
        <DivInputBoxSC>
        <DivBoxTextSC>What type of project are you looking to build? (e.g. a decentralized application, a smart contract, a blockchain platform)</DivBoxTextSC>
<TeaxArea2SC
            name="What type of project are you looking to build"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the primary goal of the project? (e.g. to enable peer-to-peer transactions, to facilitate secure data storage, to automate processes)</DivBoxTextSC>
<TeaxArea2SC
            name="What is the primary goal of the project"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC> What specific problem does your project aim to solve?</DivBoxTextSC>
<TeaxArea2SC
            name="What specific problem does your project aim to solve?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC> Who is the target audience for the project?</DivBoxTextSC>
<TeaxArea2SC
            name="Who is the target audience for the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What are the key features or functionality that the project should include?</DivBoxTextSC>
<TeaxArea2SC
            name="What are the key features or functionality that the project should include?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Is there a particular industry or market that the project is focused on serving?</DivBoxTextSC>
<TeaxArea2SC
            name="Is there a particular industry or market that the project is focused on serving?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What are the specific technical requirements for the project? (e.g. support for certain blockchain protocols, integration with certain APIs or services)</DivBoxTextSC>
<TeaxArea2SC
            name="What are the specific technical requirements for the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the timeline for the project? (e.g. expected launch date, deadlines for key milestones)</DivBoxTextSC>
<TeaxArea2SC
            name="What are the specific technical requirements for the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the budget for the project?</DivBoxTextSC>
<TeaxArea2SC
            name="What is the budget for the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project generate revenue or value?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project generate revenue or value?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Are there any existing solutions that the project will need to integrate with or compete with?</DivBoxTextSC>
<TeaxArea2SC
            name="Are there any existing solutions that the project will need to integrate with or compete with?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Are there any existing solutions that the project will need to integrate with or compete with?</DivBoxTextSC>
<TeaxArea2SC
            name="Are there any existing solutions that the project will need to integrate with or compete with?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Who are the key stakeholders for the project? (e.g. investors, customers, partners)</DivBoxTextSC>
<TeaxArea2SC
            name="Who are the key stakeholders for the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project be marketed and promoted?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project be marketed and promoted?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What are the potential risks or challenges that the project may face?</DivBoxTextSC>
<TeaxArea2SC
            name="What are the potential risks or challenges that the project may face?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the long-term vision for the project? (e.g. scalability, sustainability)</DivBoxTextSC>
<TeaxArea2SC
            name="What is the long-term vision for the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project address security and privacy concerns?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project address security and privacy concerns?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the process for making decisions about the direction of the project?</DivBoxTextSC>
<TeaxArea2SC
            name="What is the process for making decisions about the direction of the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project handle updates and maintenance?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project handle updates and maintenance?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Who will be responsible for managing the project?</DivBoxTextSC>
<TeaxArea2SC
            name="Who will be responsible for managing the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC> What is the expected level of involvement from the client?</DivBoxTextSC>
<TeaxArea2SC
            name="What is the expected level of involvement from the client?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project be tested and quality-assured?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project be tested and quality-assured?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project be deployed and made available to users?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project be deployed and made available to users?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>Will the project require any special infrastructure or hosting?</DivBoxTextSC>
<TeaxArea2SC
            name="Will the project require any special infrastructure or hosting?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What are the expectations for customer support and technical support?</DivBoxTextSC>
<TeaxArea2SC
            name="What are the expectations for customer support and technical support?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project handle data management and data governance?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project handle data management and data governance?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What are the terms of the project agreement or contract?</DivBoxTextSC>
<TeaxArea2SC
            name="What are the terms of the project agreement or contract?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the process for dispute resolution?</DivBoxTextSC>
<TeaxArea2SC
            name="What are the terms of the project agreement or contract?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>What is the process for ongoing communication and collaboration during the project?</DivBoxTextSC>
<TeaxArea2SC
            name="What is the process for ongoing communication and collaboration during the project?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC>
        <DivBoxTextSC>How will the project be evaluated for success?</DivBoxTextSC>
<TeaxArea2SC
            name="How will the project be evaluated for success?"
            type={"text"}
            id="OtherTypeSite"
         

          />
      </DivInputBoxSC>
      <DivInputBoxSC><ButtonsSendCS type="submit">Send a brief</ButtonsSendCS></DivInputBoxSC></>
  );
};

export default BlockchainNFTDevelopment;