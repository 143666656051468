import styled from "styled-components/macro";

export const DivBoxContentServiceSC = styled.div`
    /* height: calc(100vw /4) !important; */
    min-height: 190px !important;
    display: grid;
    color: white;
    grid-template-columns: 100%;
    padding-bottom: 70px;
    grid-template-rows: 33px auto;
    overflow: hidden;
    @media (min-width: 961px) {
        grid-template-rows: 80px 387px;
    }
    @media (min-width: 1420px) {
        grid-template-rows: 100px 387px;
    }
    @media (max-width: 960px) {
        padding-bottom: 0px;
    }
`;
export const DivTwoRowsSC = styled.div`
    display: grid;
    height: 100%;
    grid-template-rows: 60% 40%;
    gap: 0px;
    //justify-content: center;
    //border: 1px solid red;
    @media (max-width: 1420px) {
        grid-template-rows: repeat(2, max-content);
        gap: 50px;
    }
    @media (max-width: 700px) {
        gap: 30px;
    }
    @media (max-width: 638px) {
        grid-template-rows: 25% 75%;
        gap: 30px;
    }
    @media (max-width: 472px) {
        grid-template-rows: 25px max-content;
        gap: 30px;
    }
    @media (max-width: 420px) {
        gap: 20px;
    }
`;
export const ButtonSC = styled.button`
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    border-radius: 18px;
    padding: 10px 36px;
    font-family: "Orbitron";
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.05em;
    border: none;
    color: #ffffff;
    border-radius: 18px;
    // background: #b034fd;
    z-index: 100;
    width: max-content;
    height: 70px;
    cursor: pointer;
    @media (max-width: 1600px) {
        padding: 10px 24px;
    }
    @media (max-width: 1100px) {
        font-size: 16px;
        line-height: 20px;
        height: 55px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 30px;
    }
    @media (max-width: 530px) {
        line-height: 20px;
        font-size: 12px;
        height: 45px;
    }
    @media (max-width: 430px) {
        line-height: 14px;
        font-size: 12px;
        height: 35px;
    }
    @media (max-width: 390px) {
        line-height: 10px;
        //font-size: 10px;
        height: 30px;
    }
    color: white;
    cursor: pointer;
    :hover {
        scale: 0.97;
        transition: 0.5s;
    }
    :active {
        scale: 0.96;
        transition: 0.5s;
    }
`;
export const DivBoxSliderSC = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
`;

export const H3Sc = styled.span`
    height: 60px;
    font-family: "Ron-Medium";
    font-style: normal;
    display: grid;
    align-items: center;
    font-weight: 700;
    font-size: clamp(26px, 5vw, 80px);
    line-height: 33px;
    /* identical to box height */
    letter-spacing: 0.085em;
    margin: 0;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    width: fit-content;
    /* padding: 5px; */
    @media (min-width: 961px) {
        /* text-shadow: none; */
        line-height: 59px;
    }
    text-shadow: 0px 4px 20px rgba(213, 22, 255, 0.5);
`;

export const DivBoxItemCarouselSC = styled.div`
    height: calc(100% - 75px);
    width: calc(100vw / 2);
    max-width: 300px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    box-shadow: ${(p) => (p.isSafari ? "0px 0px 35px 0px #7167fb52" : "none")};
    border-radius: ${({ borderRadius }) => borderRadius}px;
    margin-left: 8px;
    margin-right: 8px;
    background: rgb(9, 187, 249);
    background: linear-gradient(
        90deg,
        rgba(9, 187, 249, 1) 0%,
        rgba(216, 21, 255, 1) 100%
    );
    justify-items: center;
    align-items: center;
    @media (max-width: 961px) {
        height: 250px;
        max-width: 800px;
    }
    @media (max-width: 700px) {
        height: calc(100% / 2.4);
        max-width: 800px;
    }
    @media (min-width: 961px) {
        height: 313px;
        max-width: 800px;
    }
    @media (min-width: 1420px) {
        height: 313px;
        max-width: 800px;
    }
`;

export const DivInnerItemCarouselSC = styled.div`
    display: grid;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    background: radial-gradient(
        73.28% 231.6% at 26.72% 52.96%,
        #0e1a5a 0%,
        #0e1a5a 5.27%,
        #21004b 100%
    );
    border-radius: ${({ borderRadius }) => borderRadius - 1}px;
    justify-items: center;
    align-items: center;
    text-align: center;
    position: relative;
    /* font-family: 'Kanit'; */
    font-style: normal;
    font-weight: 400;
    font-size: ${({ isSmallText }) => (isSmallText ? 8 : 25)}px;
    padding: 5px;
    box-sizing: border-box;
    @media (max-width: 700px) {
        font-size: 18px;
    }
    @media (min-width: 961px) {
        font-size: ${({ isSmallText }) => (isSmallText ? 20 : 32)}px;
    }
    @media (min-width: 1420px) {
        font-size: ${({ isSmallText }) => (isSmallText ? 32 : 64)}px;
    }
    @media (max-width: 420px) {
        font-size: 14px;
    }
`;

export const DivBoxIconSC = styled.div`
    position: absolute;
    top: ${({ fontSizeIcon }) => (fontSizeIcon === 10 ? 5 : 10)}px;
    right: ${({ fontSizeIcon }) => (fontSizeIcon === 10 ? 5 : 10)}px;
    /* height: 10px;
    width: 10px; */
    display: grid;
    color: #d516ff;
    @media (max-width: 638px) {
        display: none;
    }
`;
export const ButtonArrowLeftSC = styled.button`
    /* position: absolute; */
    z-index: 100;
    margin-left: -130px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    cursor: pointer;

    margin-top: -100px;

    @media (max-width: 480px) {
        margin-left: -27px;
        height: fit-content;
        margin-top: -50px;
        width: fit-content;
    }
`;

export const ButtonArrowRightSC = styled.button`
    /* position: absolute; */
    z-index: 100;
    margin-top: -100px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    margin-right: -20px;
    cursor: pointer;

    
  
   
    @media (max-width: 480px) {
      height: fit-content;
       margin-left: 10px;
       margin-top: -50px;
       margin-right: 23px;
       width: fit-content;
       
   }
`;
export const ButtonArrowLeft2SC = styled.button`
    /* position: absolute; */
    z-index: 100;
    margin-left: -100px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    cursor: pointer;
    
    margin-top: -100px;
  
    @media (max-width: 480px) {
        margin-left: -40px;
        height: fit-content;
        margin-top: -50px;
        width: fit-content;
    }
`;

export const ButtonArrowRight2SC = styled.button`
    /* position: absolute; */
    z-index: 100;
    margin-top: -100px;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    cursor: pointer;
    
  
   
    @media (max-width: 480px) {
      height: fit-content;
       margin-left: 4px;
       margin-top: -50px;
       width: fit-content;
       
   }



`;

export const TitleBoxSC = styled.div`
    width: 100%;
    height: fit-content;
    display: grid;
    justify-items: center;
`;

export const MarginBottomSC = styled.div`
    min-height: 190px !important;
    display: grid;
    color: white;
    grid-template-columns: 100%;
    grid-template-rows: 33px auto;
    overflow: hidden;
`;
