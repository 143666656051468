import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const DivContainerAboutDevSC = styled.div`
    display: grid;
    width: 100%;
    height: 400px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    @media (max-width: 1420px) {
        height: 300px;
    }
`;
export const DivBlure = styled.div`
    background: linear-gradient(
        90.02deg,
        rgba(23, 175, 249, 0.4) -7.59%,
        rgba(213, 22, 255, 0.4) 93.79%
    );
    -webkit-filter: blur(120px);
    filter: blur(45px);
    position: absolute;
    top: 50;

    width: 100%;
    max-width: 1175px;
    height: 100%;
    max-height: 201px;
    @media (max-width: 1420px) {
        display: none;
    }
`;
export const GridContainerItemsSC = styled.div`
    display: grid;
    width: 83%;
    justify-content: center;
    justify-items: center;
    grid-template-rows: 275px 1fr;
    grid-row-gap: 30px;
    @media (min-height: 1000px) {
        grid-row-gap: 0px;
        padding-bottom: 30px;
    }
`;
export const DivDecriptionTextSC = styled.div`
    display: grid;
    font-weight: 400;
    font-size: clamp(20px, 5vw, 40px);
    line-height: 50px;
    text-align: center;
    letter-spacing: 0.05em;
    width: 100%;
    justify-content: center;
    @media (max-width: 768px) {
        line-height: 35px;
    }
    @media (max-width: 480px) {
        line-height: 30px;
    }
    @media (max-width: 480px) {
        line-height: 25px;
    }
    
`;
export const DivLineAboutDevSC = styled.div`
    display: grid;
    height: 100%;
    //top: 80px;
    //bottom: 0;

    justify-items: center;
    align-items: center;
    position: absolute;
    pointer-events: none;
`;
export const DivCustomTextSC = styled.div`
    font-family: "Ron-Medium";
    pointer-events: none;
    text-align: center;
    background: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    max-width: max-content;
    position: relative;
    letter-spacing: 0.05em;
    margin-top: 30px;
    text-shadow: 0px 4px 15px rgba(213, 22, 255, 0.5);

`;

export const GridRowsIcons = styled.div`
    //border: 1px solid red;
    display: grid;
    grid-row-gap: 30px;
    align-self: flex-end;
`;
export const CarouselHidden = styled.div`
    width: 100%;
`;
export const GridIconsSC = styled.div`
    //border: 1px solid red;
    display: grid;
    grid-template-rows: repeat(2, 70px);
    max-width: max-content;
    max-height: max-content;
    justify-items: center;
    justify-content: center;
    grid-row-gap: 30px;
    align-self: flex-end;
    width: 100%;

    @media (max-width: 1420px) {
        display: none;
    }
`;
export const GridColumnsSecondIcons = styled.div`
    //border: 1px solid blue;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-column-gap: 30px;
    z-index: 10000000;
`;
export const GridColumnsIcons = styled.div`
    //border: 1px solid blue;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    justify-items: center;
    justify-content: center;
    z-index: 10000000;
    @media (max-width: 1420px) {
        grid-template-columns: repeat(8, 1fr);
    }
`;
export const ImgIconSC = styled.div`
    border-radius: 100%;
    width: 180px;
    height: 180px;
    background-image: ${({ Photo }) => Photo && `url(${Photo})`};
    background-repeat: no-repeat;
    background-size: 100%;

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
`;
export const ImgGradientCircleSC = styled.div`
    border-radius: 100%;
    width: 180px;
    height: 180px;
    box-sizing: border-box;
    display: grid;
    justify-items: center;
    align-items: center;
    text-align: center;
    position: relative;
    border: 10px solid transparent;
    border-image: linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    -moz-border-image: -moz-linear-gradient(270deg, #d815ff 0%, #09bbf9 102.1%);
    -webkit-border-image: -webkit-linear-gradient(
        180deg,
        #d815ff 0%,
        #09bbf9 102.1%
    );
    border-image-slice: 1;
`;
export const DivCustomArrowRight = styled(IoIosArrowForward)`
    width: 30px;
    height: 30px;
    position: absolute;
    left: 52%;
    top: 70%;
    z-index: 100;
    cursor: pointer;
    @media (max-width: 480px) {
        height: 20px;
        width: 20px;
    }
`;

export const DivCustomArrowLeft = styled(IoIosArrowBack)`
    width: 30px;
    height: 30px;
    position: absolute;
    right: 52%;
    z-index: 100;
    cursor: pointer;
    top: 70%;
    @media (max-width: 480px) {
        height: 20px;
        width: 20px;
    }
`;
export const DivWrapSC = styled.div`
    display: grid;
    height: max-content;
    // border:1px solid red;
`;

export const DivContainerSC = styled.div`
    margin: 0 auto;
    max-width: var(--max-width);
    /* padding-bottom: 25px; */
    display: grid;
    justify-items: center;
    width: 100vw;
    // border: 1px solid red;
`;
