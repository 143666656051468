import React, { useState } from "react";
import {
    DivContainerBoxSC,
    DivFormBoxSC,
    DivFormDataBoxSC,
    DivInputBoxSC,
    H1TitleBoxSC,
    InputSC,
    LabelInputSC,
    LinkNavBarBoxSC,
    LogoNavBarSC,
    TextareaSC,
    TextNavBarSC,
    SpanBudgetSC,
    DivBoxColumnsMoneySC,
    DivBoxTextSC,
    TeaxAreaSC,
    LabelTeaxtAreaSC,
    ButtonsSendCS,
} from "../../styles/styled.brief";
import InputMask from "react-input-mask";

import SoftwareDeveloperBreaf from "./SoftvareDeveloperBreaf";
import UIUXDesignBreaft from "./UIUXDesignBreaft";
import BlockchainNFTDevelopment from "./BlockchainNFTDevelopment";

import Marketing from "./Marketing";


const Brief = () => {
    const [phone, setPhone] = useState("");
    const [budget, setBudget] = useState("");
    const [focusedPhone, setFocusedPhone] = useState("");
    const [focusedBudget, setFocusedBudget] = useState("");

    const handlePhoneInput = ({ target }) => {
        setFocusedPhone(target.id);
    };

    const handleBudgetInput = ({ target }) => {
        setFocusedBudget(target.id);
    };
    const [terms, setTerms] = useState(false);
    const [terms2, setTerms2] = useState(false);
    const [terms3, setTerms3] = useState(false);
    const [typeproduct, settypeproduct] = useState("Software development");
    const [mobileapp, setmobileapp] = useState(false);
    const Terms = () => {
        setTerms(!terms);
    };
    const Terms2 = () => {
        setTerms2(!terms2);
    };
    const Terms3 = () => {
        setTerms3(!terms3);
    };
    function chengeValue(e) {
        settypeproduct(e.target.value);
    }

    return (
        <div
            style={{
                background: "white",
                paddingBottom: "100px",
                height: "100%",
            }}
        >

            <DivContainerBoxSC>
                <LinkNavBarBoxSC to={"/"}>
                    <LogoNavBarSC />
                    <TextNavBarSC>Cyberzen Labs</TextNavBarSC>
                </LinkNavBarBoxSC>
                <DivFormBoxSC>
                    <form

action="https://formsubmit.co/cyberzenlabs@gmail.com"
                     method="POST"
                     enctype="multipart/form-data">
       
                        <DivFormDataBoxSC>
                            <H1TitleBoxSC>General information</H1TitleBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="name">Name</LabelInputSC>
                                <InputSC name="name" type={"text"} id="name" required />
                            </DivInputBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="email">E-mail</LabelInputSC>
                                <InputSC
                                    name="email"
                                    type={"email"}
                                    id="email"
                                    required
                                />
                            </DivInputBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="phone">Phone*</LabelInputSC>
                                <InputMask
                                    mask="+1 (999) 999-99-99"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    disabled={false}
                                    maskChar=" "
                                >
                                    {() => (
                                        <InputSC
                                            // onChange={(e) => handleTelInput(e)}
                                            name="phone"
                                            type={"tel"}
                                            id="phone"
                                            inputMode="tel"
                                            onFocusCapture={handlePhoneInput}
                                            value={phone}
                                            required
                                        />
                                    )}
                                </InputMask>
                            </DivInputBoxSC>

                            <DivInputBoxSC>
                                <LabelInputSC for="company_name">
                                    Company name
                                </LabelInputSC>
                                <InputSC
                                    name="company_name"
                                    type={"text"}
                                    id="company_name"
                                    required
                                />
                            </DivInputBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="subject_of_activity">
                                    What the company does
                                </LabelInputSC>
                                <TextareaSC
                                    name="subject_of_activity"
                                    id="subject_of_activity"
                                    required
                                />
                            </DivInputBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="references">
                                    Site addresses or company names of
                                    competitors
                                </LabelInputSC>
                                <TextareaSC name="references" id="references" required/>
                            </DivInputBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="lead_times">
                                    Lead times
                                </LabelInputSC>
                                <TextareaSC name="lead_times" id="lead_times" required/>
                            </DivInputBoxSC>
                            <DivInputBoxSC>
                                <LabelInputSC for="budget">Budget</LabelInputSC>
                                <InputMask
                                    mask="$ 999,999,999,999"

                                    value={budget}
                                    onChange={(e) => setBudget(e.target.value)}
                                    disabled={false}
                                    maskChar=" "
                                >
                                    {() => (
                                        <InputSC
                                        required
                                            name="budget"
                                            type={"text"}
                                            id="budget"
                                            inputMode="decimal"
                                            onFocusCapture={handleBudgetInput}
                                            value={budget}
                                        />
                                    )}
                                </InputMask>
                            </DivInputBoxSC>
                            <DivInputBoxSC>

                            <DivBoxTextSC>Goals and functions of the product</DivBoxTextSC>
                      <DivBoxColumnsMoneySC>
                        <label className="label2">
                          <input
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"Software development"}
                            checked={typeproduct == 'Software development' ? true : false}
                            onChange={chengeValue}
                          />
                          <span className="fake2"></span>
                          <SpanBudgetSC className="text1">
                          Software development
                          </SpanBudgetSC>
                        </label>
                        <label className="label2">
                          <input
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"UI/UX design"}
                            checked={typeproduct == 'UI/UX design' ? true : false}
      onChange={chengeValue}
                          />
                          <span className="fake2"></span>
                          <SpanBudgetSC className="text">
                          UI/UX design
                          </SpanBudgetSC>
                        </label>
                        <label className="label2">
                          <input
                             id="3"
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"Blockchain / NFT development"}
                            checked={typeproduct == 'Blockchain / NFT development' ? true : false}
                            onChange={chengeValue}
                          />
                          <span className="fake2"></span>
                          <SpanBudgetSC className="text1">
                          Blockchain / NFT development
                          </SpanBudgetSC>
                        </label>
                        <label className="label2">
                          <input
                             id="3"
                            type="radio"
                            className="radio"
                            name="radio-click"
                            value={"Marketing"}
                            checked={typeproduct == 'Marketing' ? true : false}
                            onChange={chengeValue}
                          />
                          <span className="fake2"></span>
                          <SpanBudgetSC className="text1">
                         Marketing
                          </SpanBudgetSC>
                        </label>
               
                      </DivBoxColumnsMoneySC>
                      </DivInputBoxSC>
                      
                      {typeproduct=='Software development'?(<SoftwareDeveloperBreaf></SoftwareDeveloperBreaf>):null}
                      {typeproduct=='UI/UX design'?(<UIUXDesignBreaft></UIUXDesignBreaft>):null}
                      {typeproduct=='Blockchain / NFT development'?(<BlockchainNFTDevelopment></BlockchainNFTDevelopment>):null}
                      {typeproduct=='Marketing'?(<Marketing></Marketing>):null}
                        </DivFormDataBoxSC>
                    </form>
                </DivFormBoxSC>
            </DivContainerBoxSC>
        </div>
    );
};

export default Brief;
